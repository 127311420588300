import React from "react";
import { Input, Pagination, PaginationProps, Segment, Table } from "semantic-ui-react";
import { ItemsPerPageDropdown } from "../ItemsPerPageDropdown";
import { CustomTableHead } from "../CustomTableHead";
import { CustomTableRow } from "../CustomTableRow";
import { ControlledDynamicItemsViewerProps } from "./ControlledDynamicItemsViewerProps";

export const ControlledItemsTableView = <T,>(props: ControlledDynamicItemsViewerProps<T>) => {

    const { properties, items, itemActions } = props;

    const getNumberOfPages = () => {
            return props.pagination ? Math.ceil(props.numberOfItems / props.numberOfItemsShown) : 1;
    }

    const handleClick = (item) => {
        if (props.onItemsClick) {
            props.onItemsClick(item);
        }
    }

    return (
        <>
            {(properties.some(pr => pr.searchable) || props.pagination || props.customTools) &&
                <Segment attached={props.attached}>
                    <div className="items-view-row items-view-flex-row">
                        {properties.some(pr => pr.searchable) &&
                            <div className="items-view-flex-item">
                                <Input
                                    style={{ width: "20em" }}
                                    icon='search'
                                    placeholder='Search...'
                                    large="true"
                                    value={props.searchKeyWord}
                                    className='left floated'
                                    onChange={(event, data) => {
                                        if (props.onSearchKeyWordChanged) {
                                            props.onSearchKeyWordChanged(data.value);
                                        }

                                        if (props.onActivePageChanged) {
                                            props.onActivePageChanged(1);
                                        }
                                    }}
                                />
                        </div>
                        }
                        {props.customTools && (
                                     props.customTools.map(el => {
                                        return (
                                            <div className="items-view-flex-item items-view-flex-item-middle">
                                                    {el}
                                            </div>
                                        )
                                    })
                                )
                        }
                        
                        {props.pagination &&
                           <div className="items-view-row items-view-flex-row">
                                <div className="items-view-flex-item items-per-page-dropdown">
                                    <ItemsPerPageDropdown
                                        noOfItemsToShowArray={props.noOfItemsToShowArray}
                                        setItemsPerPage={(newState: number) => {
                                            if (props.onNumberOfItemsShownChanged) {
                                                props.onNumberOfItemsShownChanged(newState);
                                            }
                                            if (props.onActivePageChanged){
                                                props.onActivePageChanged(1);
                                            }
                                        }}
                                        selectedValue={props.numberOfItemsShown}
                                    />
                                </div>
                                <div className="items-view-flex-item">
                                    <Pagination
                                        boundaryRange={0}
                                        siblingRange={1}
                                        size='small'
                                        activePage={props.activePage}
                                        totalPages={getNumberOfPages()}
                                        onPageChange={(e, pageInfo: PaginationProps) => props.onActivePageChanged && props.onActivePageChanged(pageInfo.activePage as number)}
                                        />
                                </div>
                            </div>
                        }
                    </div>
                </Segment>
            }
            <Table celled compact sortable selectable>
                <CustomTableHead
                    properties={properties}
                    onSortingColumnChanged={props.onSortingPropertyChanged}
                    onSortingOrderChanged={props.onSortingDirectionChanged}
                    itemActions={props.itemActions}
                />
                <Table.Body>
                    {
                        items.map((item, index) => (
                            <CustomTableRow
                                key={index}
                                item={item}
                                properties={properties}
                                handleRowClick={handleClick}
                                itemActions={itemActions}
                            />
                        ))
                    }
                </Table.Body>
            </Table >
        </>
    );

}
