import { DynamicEntityFieldType } from "./DynamicEntityFieldType";
import moment from "moment";
export class DynamicEntityFieldValue {
    constructor() {
        this.valueText = null;
        this.valueInt = null;
        this.valueLong = null;
        this.valueDouble = null;
        this.valueBoolean = null;
        this.valueDateTime = null;
        this.valueGuid = null;
    }
    getObjectValue(type, dateFormat = "DD-MM-YYYY") {
        let objVal = null;
        switch (type) {
            case DynamicEntityFieldType.String:
                objVal = this.valueText;
                break;
            case DynamicEntityFieldType.Boolean:
                objVal = this.valueBoolean;
                break;
            case DynamicEntityFieldType.Integer:
                objVal = this.valueInt;
                break;
            case DynamicEntityFieldType.Float:
                objVal = this.valueDouble;
                break;
            case DynamicEntityFieldType.Long:
                objVal = this.valueLong;
                break;
            case DynamicEntityFieldType.Date:
                objVal = moment(this.valueDateTime, moment.ISO_8601, true).isValid() ? moment(this.valueDateTime).format(dateFormat) : this.valueDateTime;
                break;
            case DynamicEntityFieldType.Lookup:
                objVal = this.valueGuid == "00000000-0000-0000-0000-000000000000" ? null : this.valueGuid;
                break;
            case DynamicEntityFieldType.LongString:
                objVal = this.valueText;
                break;
            case DynamicEntityFieldType.Array:
                objVal = this.valueArray;
                break;
        }
        ;
        return objVal;
    }
    setObjectValue(objVal, type, dateFormat = "DD-MM-YYYY") {
        switch (type) {
            case DynamicEntityFieldType.String:
                this.valueText = objVal;
                break;
            case DynamicEntityFieldType.Boolean:
                this.valueBoolean = objVal;
                break;
            case DynamicEntityFieldType.Integer:
                this.valueInt = objVal;
                break;
            case DynamicEntityFieldType.Float:
                this.valueDouble = objVal;
                break;
            case DynamicEntityFieldType.Long:
                this.valueLong = objVal;
                break;
            case DynamicEntityFieldType.Date:
                this.valueDateTime = moment(objVal, dateFormat, true).isValid() ? moment(objVal, dateFormat).format() : objVal;
                break;
            case DynamicEntityFieldType.Lookup:
                this.valueGuid = objVal ? objVal : "00000000-0000-0000-0000-000000000000";
                break;
            case DynamicEntityFieldType.LongString:
                this.valueText = objVal;
                break;
            case DynamicEntityFieldType.Array:
                this.valueArray = objVal;
                break;
        }
        ;
        return objVal;
    }
}
