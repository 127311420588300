import React from "react";
import { Button, Popup, Table, TableCellProps } from "semantic-ui-react";
import { ItemDisplayConfig } from "./ItemDisplayConfig";
import { getItemContent } from "../../Utility/utility";
import { ActionProps } from "./ActionProps";

interface CustomTableRowProps<T> {
    item: T;
    properties: ItemDisplayConfig<T>[];
    handleRowClick: (item) => void;
    itemActions: ActionProps[];
}

const CustomTableRow = <T,>(props: CustomTableRowProps<T>) => {
    const { properties, item, itemActions } = props;
    return (
        <Table.Row>
            {properties
                .filter((property) => property.displayPlaces?.indexOf("table") > -1)
                .map((property, index) => {
                    var hasColumnWidth = property.columnWidth;
                    var tableCellProps: TableCellProps = {
                        ...(hasColumnWidth && { width: property.columnWidth }),
                        key: index
                    };

                    return (
                        <Table.Cell
                            {...tableCellProps}
                            onDoubleClick={() => {
                                if (props.handleRowClick) {
                                    props.handleRowClick(item);
                                }
                            }}
                            onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                            }}
                        >
                            {getItemContent(props.item, [
                                property.key,
                                property.customRender,
                                property.formaters,
                                property.customRenderUseFormatData
                            ]) as React.ReactNode }
                        </Table.Cell>
                    );
                })}
            {itemActions &&
                <Table.Cell textAlign="center" key={"itemActions_cell"} width={1}>
                    <Button.Group>
                    {itemActions.map((actionProps, index) => {
                        var actionKey = actionProps.key + "_" + index;
                        const { displayOnCard, onClick, onHoverDescription, ...buttonProps } = actionProps;
                        return (
                            onHoverDescription ? (
                                <Popup
                                    key={index}
                                    content={onHoverDescription}
                                    mouseEnterDelay={500}
                                    mouseLeaveDelay={500}
                                    trigger={<Button key={actionKey} onClick={() => onClick(item)} {...buttonProps} />}
                                />
                            ) : (
                                <Button key={actionKey} onClick={() => onClick(item)} {...buttonProps} />
                            )
                        )
                    })}
                    </Button.Group>
                </Table.Cell>
            }
        </Table.Row>
    );
};

export { CustomTableRow, CustomTableRowProps };
