
import { DynamicFieldDependecyType, DynamicFieldDependencyBase, DynamicFieldLookupDependencyBase } from "eyam-webui-models";

class FieldDependencyNode {
    fieldID: string;
}

class FieldDependencyEdge {
    from: string;
    to: string;
}

export class FieldDependencyGraph {

    private _adjList: Map<string, string[]> = new Map<string, string[]>();
    private _transpAdjList: Map<string, string[]> = new Map<string, string[]>();

    private _adjDepMatr: Map<string, Map<string, DynamicFieldDependencyBase<DynamicFieldLookupDependencyBase>[]>> = new Map<string, Map<string, DynamicFieldDependencyBase<DynamicFieldLookupDependencyBase>[]>>();
    private _transpAdjDepMatr: Map<string, Map<string, DynamicFieldDependencyBase<DynamicFieldLookupDependencyBase>[]>> = new Map<string, Map<string, DynamicFieldDependencyBase<DynamicFieldLookupDependencyBase>[]>>();

    private _nodeList: {
        [key: string]: FieldDependencyNode
    } = {};

    initialize = (dependencyList: DynamicFieldDependencyBase<DynamicFieldLookupDependencyBase>[]) => {

        let nodeListMap = new Map<string, string>();
        let edgeList: FieldDependencyEdge[] = [];
        let transEdgeList: FieldDependencyEdge[] = [];

        dependencyList.forEach(dep => {
            if (!nodeListMap.has(dep.customFieldID)) {
                nodeListMap.set(dep.customFieldID, dep.customFieldID);
            }

            if (!nodeListMap.has(dep.dependsOnCustomFieldID)) {
                nodeListMap.set(dep.dependsOnCustomFieldID, dep.dependsOnCustomFieldID);
            }

            if (!this._adjDepMatr.has(dep.customFieldID)) {
                this._adjDepMatr.set(dep.customFieldID, new Map<string, DynamicFieldDependencyBase<DynamicFieldLookupDependencyBase>[]>());
            }

            if (!this._adjDepMatr.get(dep.customFieldID).has(dep.dependsOnCustomFieldID)) {
                this._adjDepMatr.get(dep.customFieldID).set(dep.dependsOnCustomFieldID, []);
            }

            this._adjDepMatr.get(dep.customFieldID).get(dep.dependsOnCustomFieldID).push(dep);

            if (!this._transpAdjDepMatr.has(dep.dependsOnCustomFieldID)) {
                this._transpAdjDepMatr.set(dep.dependsOnCustomFieldID, new Map<string, DynamicFieldDependencyBase<DynamicFieldLookupDependencyBase>[]>());
            }

            if (!this._transpAdjDepMatr.get(dep.dependsOnCustomFieldID).has(dep.customFieldID)) {
                this._transpAdjDepMatr.get(dep.dependsOnCustomFieldID).set(dep.customFieldID, []);
            }

            this._transpAdjDepMatr.get(dep.dependsOnCustomFieldID).get(dep.customFieldID).push(dep);


            var depEdge = new FieldDependencyEdge();
            var transDepEdge = new FieldDependencyEdge();

            if (!edgeList.some(e => e.from == dep.customFieldID && e.to == dep.dependsOnCustomFieldID)) {

                depEdge.from = dep.customFieldID;
                depEdge.to = dep.dependsOnCustomFieldID;

                if (!this._adjList.has(dep.customFieldID)) {
                    this._adjList.set(dep.customFieldID, []);
                }

                this._adjList.get(dep.customFieldID).push(dep.dependsOnCustomFieldID);


                transDepEdge.to = dep.customFieldID;
                transDepEdge.from = dep.dependsOnCustomFieldID;


                if (!this._transpAdjList.has(dep.dependsOnCustomFieldID)) {
                    this._transpAdjList.set(dep.dependsOnCustomFieldID, []);
                }
                this._transpAdjList.get(dep.dependsOnCustomFieldID).push(dep.customFieldID);

                edgeList.push(depEdge);
                transEdgeList.push(transDepEdge);


            } else {

                //TODO: figure out to handle mutiple dependecy types on the same edge

            }
        });

        for (var k of nodeListMap.values()) {
            this._nodeList[k] = new FieldDependencyNode();
            if (!this._transpAdjList.has(k)) {
                this._transpAdjList.set(k, []);
            }

            if (!this._adjList.has(k)) {
                this._adjList.set(k, []);
            }
        }

    }

    topo = () => {
        this.topologicalSort(this._adjList);
    }

    topoReverse = () => {
        this.topologicalSort(this._transpAdjList);
    }

    topologicalSort = (adjList: Map<string, string[]>) => {

        let indegree = new Map<string, number>();

        for (let k of adjList.keys()) {
            indegree.set(k, 0);
        }

        for (let adLst of adjList.values()) {
            for (let node = 0; node < adLst.length; node++) {
                let cVal = indegree.get(adLst[node]);
                indegree.set(adLst[node], ++cVal);
            }
        }

        let q = [];
        for (let k of indegree.keys()) {
            if (indegree.get(k) == 0) {
                q.push(k);
            }
        }

        let cnt = 0;

        let topOrder: string[] = [];
        while (q.length != 0) {
            let u = q.shift();
            topOrder.push(u);

            let cAdjList = adjList.get(u);

            for (let node = 0; node < cAdjList.length; node++) {
                let ctp = indegree.get(cAdjList[node]);
                ctp--;
                indegree.set(cAdjList[node], ctp);
                if (ctp == 0) {
                    q.push(cAdjList[node]);
                }
            }
            cnt++;
        }

        // Check if there was a cycle
        if (cnt != adjList.size) {
            console.log("Graph has cycle");
            return;
        }

        return topOrder;
    }


    getFormEffectOrder = (): string[] => {
        return this.topologicalSort(this._transpAdjList);
    }

    triggerEffectsForField = (fieldID: string, effectFn: (cFieldID: string, parentField: string, cDependicies: DynamicFieldDependencyBase<DynamicFieldLookupDependencyBase>[]) => boolean) => {

        if (this._transpAdjList.has(fieldID)) {

            var affectedNodes = this._transpAdjList.get(fieldID);

            for (let node of affectedNodes) {

                if (this._transpAdjDepMatr.has(fieldID) && this._transpAdjDepMatr.get(fieldID).has(node)) {
                    let needsCascade = effectFn(node, fieldID, this._transpAdjDepMatr.get(fieldID).get(node));

                    if (needsCascade) {
                        this.triggerEffectsForField(node, effectFn);
                    }
                }
            }
        }
    }
}
