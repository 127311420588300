export class DEAppViewBase {
    constructor() {
        this.id = "";
        this.name = "";
        this.localizedKey = "";
        this.displayOrder = 0;
        this.icon = "";
        this.viewGroupID = "";
        this.metadata = "";
    }
}
