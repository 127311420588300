export class ExternalTrigger {
    constructor() {
        this.externalTriggerId = "";
        this.name = "";
        this.triggerUrl = "";
        this.triggerCustomPayload = "";
        this.fieldAssociations = [];
        this.headers = [];
    }
}
