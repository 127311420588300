import React from "react";
import { useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { ItemDisplayConfig } from "./ItemDisplayConfig";

const SortingPropertiesDropdown = (props: { setSortingProperty; setSortingOrder; properties: ItemDisplayConfig<any>[] }) => {
    const [text, setText] = useState("Sort by ");
    return (
        <Dropdown text={text}>
            <Dropdown.Menu>
                {props.properties
                    .filter((col) => col.sortable)
                    .map((item, index) => {
                        return (
                            <Dropdown.Item
                                key={index}
                                onClick={(event, data) => {
                                    if (props.setSortingProperty) {
                                        props.setSortingProperty(data.value);
                                    }

                                    if (props.setSortingOrder) {
                                        props.setSortingOrder("arrow down");
                                    }

                                    setText("Sort by " + data.text);
                                }}
                                text={item.label}
                                value={item.key as string}
                            />
                        );
                    })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export { SortingPropertiesDropdown };
