import React from "react";
import { Button, List, Segment } from "semantic-ui-react";
import { getItemContent } from "../../Utility/utility";
import { ItemDisplayConfig } from "./ItemDisplayConfig";
import { ActionProps } from "./ActionProps";

interface ItemCardProps<T> {
    item: T;
    properties: ItemDisplayConfig<T>[];
    itemActions: ActionProps[];
}

const ItemCard = <T,>(props: ItemCardProps<T>) => {
    return (
        <Segment>
            <List relaxed>
                {props.properties
                    .filter((property) => property.displayPlaces?.indexOf("cardHeader") > -1)
                    .map((property, index) => {
                        return (
                            <List.Item as={"h3"} key={index}>
                                <List.Header>{property.label}</List.Header>
                                <List.Content>
                                    {getItemContent(props.item, [
                                        property.key,
                                        property.customRender,
                                        property.formaters,
                                        property.customRenderUseFormatData
                                    ]) as React.ReactNode}
                                </List.Content>
                            </List.Item>
                        );
                    })}
                {props.properties
                    .filter((property) => property.displayPlaces?.indexOf("card") > -1)
                    .map((property, index) => {
                        return (
                            <List.Item key={index}>
                                <List.Header>{property.label}</List.Header>
                                <List.Description>
                                    {getItemContent(props.item, [
                                        property.key,
                                        property.customRender,
                                        property.formaters,
                                        property.customRenderUseFormatData
                                    ]) as React.ReactNode}
                                </List.Description>
                            </List.Item>
                        );
                    })}
                {props.itemActions && (
                    <List.Item>
                        <List.Content>
                            {props.itemActions.map((actionProps, index) => {
                                const actionKey = actionProps.key + "_" + index + "_card";
                                const { displayOnCard, onClick, onHoverDescription, ...buttonProps } = actionProps;

                                if (displayOnCard) {
                                    return (
                                        <Button
                                            key={actionKey}
                                            onClick={(event, data) => {
                                                actionProps.onClick(props.item);
                                            }}
                                            {...buttonProps}
                                        />
                                    );
                                }
                            })}
                        </List.Content>
                    </List.Item>
                )}
            </List>
        </Segment>
    );
};

export { ItemCard, ItemCardProps };
