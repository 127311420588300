export class LookupTableValueBase {
    constructor() {
        this.id = "";
        this.parentID = "";
        this.lookupTableID = "";
        this.name = "";
        this.localizedKey = "";
        this.metadata = "";
    }
}
