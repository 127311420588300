export class DynamicEntityBase {
    constructor() {
        this._fieldValues = new Map();
    }
    get fieldValues() { return this._fieldValues; }
    set fieldValues(fieldValues) { this._fieldValues = fieldValues; }
    getFieldValuesMapped() {
        let res = {};
        this._fieldValues.forEach((val, key) => {
            res[key] = val;
        });
        return res;
    }
    getObjectFieldValueByType(fieldId, fieldType, dateFormat = "DD-MM-YYYY") {
        let fieldValue = this.getFieldValue(fieldId);
        return fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.getObjectValue(fieldType, dateFormat);
    }
    getFieldValue(key) {
        if (this._fieldValues) {
            return this._fieldValues.get(key);
        }
        return null;
    }
    setFieldValue(key, val) {
        if (this._fieldValues) {
            this._fieldValues.set(key, val);
        }
    }
}
