import React, { useState, useEffect, useRef } from "react";
import { Icon, Image, SemanticICONS, Input, Button } from "semantic-ui-react";

export const ScannerInput: React.FunctionComponent<{ onItemScanned: (value: string) => void, inputPlaceholderText?: string, autoFocus?: boolean }> = (props) => {

    const [scannedItemID, setScannedItemID] = useState<string>("");
    const [autoFocus, setAutoFocus] = useState<boolean>(false);
    const [forceFocusIntId, setForceFocusIntId] = useState<number>(null);
    const comandInput = useRef(null);

    const checkEnter = (event: any) => {
        if (event.keyCode == 13) {
            props.onItemScanned(scannedItemID);
            setScannedItemID("");
        }
    }


    useEffect(() => {
        if (autoFocus) toggleAutoFocus();
    }, [])

    const startForceFocusInterval = () => {
        if (forceFocusIntId != null) {
            window.clearInterval(forceFocusIntId);
        }

        let fintId = window.setInterval(() => {
            if (comandInput && comandInput.current != null) {
                comandInput.current.focus();
            }
        }, 400);

        setForceFocusIntId(fintId);
    }

    const stopForceFocusInterval = () => {

        if (forceFocusIntId != null) {
            window.clearInterval(forceFocusIntId);

            if (comandInput && comandInput.current != null) {
                comandInput.current.blur();
            }
        }
    }

    const toggleAutoFocus = () => {
        if (autoFocus) {
            stopForceFocusInterval();
        } else {
            startForceFocusInterval();
        }

        setAutoFocus(!autoFocus);
    }




    return (
        <div className="scan-entry">
            <Icon onClick={() => { toggleAutoFocus(); }} name="dot circle" className={"scan-entry__scan-icon" + (autoFocus ? " active-scan" : "")} />
            <input
                className="ui input scan-entry__scan-input"
                placeholder={props.inputPlaceholderText}
                value={scannedItemID}
                onKeyUp={checkEnter}
                ref={comandInput}
                onChange={(event) => { setScannedItemID(event.target.value); }}
            />
            <Button floated="right" type="submit" primary content="Submit"
                onClick={(event) => {
                    event.preventDefault();
                    props.onItemScanned(scannedItemID);
                    setScannedItemID("")
                }}
                positive
                className="scan-entry__submit-btn" />
        </div>
    );
};