import React, { useContext } from "react";
import { NavigationInfo } from "./NavigationUtils";

export const NavigationContext = React.createContext<NavigationInfo>({
    pageTitle: "",
    setTitle: (value: string) => { },
    navigationSubject: null,
    onBackButtonClick: () => {
    }
});



export const useNavigationContext = () => useContext(NavigationContext);
