import React, { useEffect, useState } from "react";
import { Button, Icon, Input, List, Segment } from "semantic-ui-react";

export interface ListBoxProps {
    options: ListBoxOption[];
    selectedOptions: (string | number)[];
    onChange: (selectedOptions: (string | number)[]) => any;
}

export interface ListBoxOption {
    key: string | number;
    text?: string;
    value?: boolean | number | string;
}

export const DualListBox: React.FunctionComponent<ListBoxProps> = (props) => {
    const [leftOptions, setLeftOptions] = useState<ListBoxOption[]>([]);
    const [rightOptions, setRightOptions] = useState<ListBoxOption[]>([]);
    const [selectedRightItem, setSelectedRightItem] = useState<string | number>(null);
    const [selectedLeftItem, setSelectedLeftItem] = useState<string | number>(null);
    const [leftSearch, setLeftSearch] = useState("");
    const [rightSearch, setRightSearch] = useState("");

    useEffect(() => {
        let leftOptions = props.options.filter((o) => (props.selectedOptions || []).indexOf(o.key) === -1);
        if (leftSearch) {
            leftOptions = leftOptions.filter(
                (o) => o.text.toLowerCase().includes(leftSearch.toLowerCase()) || String(o.key) === leftSearch
            );
        }
        setLeftOptions(leftOptions.sort(compare));
    }, [props.selectedOptions, leftSearch]);

    useEffect(() => {
        let rightOptions: ListBoxOption[] = [];

        if (props.selectedOptions?.length > 0) {
            for (let option of props.selectedOptions) {

                let boxOption = props.options.find((o) => o.key === option);
                if (boxOption) {
                    rightOptions.push(boxOption);
                }
            }
        }
        
        if (rightSearch) {
            rightOptions = rightOptions.filter(
                (o) => o.text.toLowerCase().includes(rightSearch.toLowerCase()) || String(o.key) === rightSearch
            );
        }
        setRightOptions(rightOptions);
    }, [props.selectedOptions, rightSearch]);

    const compare = (a: ListBoxOption, b: ListBoxOption) => {
        const first = a.text.toLowerCase();
        const second = b.text.toLowerCase();
        return first > second ? 1 : -1;
    };

    const renderLeftBoxOptions = () => {
        const elements = leftOptions.map((o) => {
            const { key } = o;
            return (
                <List.Item as="a" active={selectedLeftItem === key}  onClick={() => setSelectedLeftItem(key)} key={key} onDoubleClick={addItem} 
                style={{
                    fontWeight:selectedLeftItem === key?'bold':'normal'
                  }} 
                >
                    {o.text} 
                </List.Item>
            );
        });
        return (
            <List className="dual-list-box-options" link size="large">
                {elements}
            </List>
        );
    };

    const renderRightBoxOptions = () => {
        const elements = rightOptions.map((o) => {
            const { text, key } = o;
            return (
                <List.Item as="a" active={selectedRightItem === key} onClick={() => setSelectedRightItem(key)} key={key} onDoubleClick={removeItem} 
                style={{
                    fontWeight:selectedRightItem === key?'bold':'normal'
                  }}>
                    {text}
                    
                </List.Item>
            );
        });
        return (
            <List className="dual-list-box-options" link size="large">
                {elements}
            </List>
        );
    };

    const addItem = () => {
        if (!selectedLeftItem || leftOptions.findIndex((o) => o.key === selectedLeftItem) === -1) {
            return;
        }
        props.onChange((props.selectedOptions || []).concat(selectedLeftItem));
    };

    const addAllItems = () => {
        const items = (props.selectedOptions || []).concat(leftOptions.map((o) => o.key));
        props.onChange(items);
    };

    const removeItem = () => {
        if (!selectedRightItem || rightOptions.findIndex((o) => o.key === selectedRightItem) === -1) {
            return;
        }
        let selected = (props.selectedOptions || []).filter((o) => o !== selectedRightItem);
        props.onChange(selected);
    };

    const removeAllItems = () => {
        let selected = (props.selectedOptions || []).filter((key) => rightOptions.findIndex((o) => o.key === key) === -1);
        props.onChange(selected);
    };

    return (
            <Segment.Group horizontal>
                <Segment basic>
                    <Input className="input-only-bottom-border"
                        icon="search"
                        placeholder="Search..."
                        large="true"
                        onChange={(_, data) => setLeftSearch(data.value)}
                    />
                    {renderLeftBoxOptions()}
                </Segment>
                <Segment basic className="dual-list-button-wrapper">
                    <Button.Group size="tiny" vertical>
                        <Button icon onClick={addAllItems}>
                            <Icon size="large" name="angle double right"></Icon>
                        </Button>
                        <Button  icon onClick={addItem}>
                            <Icon size="large" name="angle right"></Icon>
                        </Button>
                        <Button  icon onClick={removeItem}>
                            <Icon size="large" name="angle left"></Icon>
                        </Button>
                        <Button  icon onClick={removeAllItems}>
                            <Icon size="large" name="angle double left"></Icon>
                        </Button>
                    </Button.Group>
                </Segment>
                <Segment basic>
                    <Input className="input-only-bottom-border" icon="search" placeholder="Search..." large="true" onChange={(_, data) => setRightSearch(data.value)} />
                    {renderRightBoxOptions()}
                </Segment>
           </Segment.Group>
    );
};
