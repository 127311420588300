export var DynamicEntityQueryFilterType;
(function (DynamicEntityQueryFilterType) {
    DynamicEntityQueryFilterType[DynamicEntityQueryFilterType["AND"] = 0] = "AND";
    DynamicEntityQueryFilterType[DynamicEntityQueryFilterType["OR"] = 1] = "OR";
    DynamicEntityQueryFilterType[DynamicEntityQueryFilterType["LIKE"] = 2] = "LIKE";
    DynamicEntityQueryFilterType[DynamicEntityQueryFilterType["EQ"] = 3] = "EQ";
    DynamicEntityQueryFilterType[DynamicEntityQueryFilterType["GT"] = 4] = "GT";
    DynamicEntityQueryFilterType[DynamicEntityQueryFilterType["LT"] = 5] = "LT";
    DynamicEntityQueryFilterType[DynamicEntityQueryFilterType["GTE"] = 6] = "GTE";
    DynamicEntityQueryFilterType[DynamicEntityQueryFilterType["LTE"] = 7] = "LTE";
    DynamicEntityQueryFilterType[DynamicEntityQueryFilterType["IN"] = 8] = "IN";
})(DynamicEntityQueryFilterType || (DynamicEntityQueryFilterType = {}));
