
import React, { Suspense } from 'react';
import { BrowserRouter } from "react-router-dom";
import 'semantic-ui-less/semantic.less';
import './scss/appstyles.scss';
import { MsalProvider } from "@azure/msal-react";
import "./app/Localization/i18n";
import reportWebVitals from './reportWebVitals';
import { EYDPCA } from './app/Auth/AuthConfig';
import { Dimmer, Loader } from 'semantic-ui-react';
import { SpaSettings } from 'eyam-webui-components';
import { EYDAppAuthWrapper } from './app/EYDAppAuthWrapper';
import { createRoot } from 'react-dom/client';



//import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Add this in node_modules/react-dom/index.js
//window.React1 = require('react');

declare global {
    interface Window {
        spaSettings: SpaSettings
    }
}

const container = document.getElementById('root');
const root = createRoot(container!); 

root.render(<MsalProvider instance={EYDPCA}>
    
        <Suspense fallback={<Dimmer><Loader size='big'></Loader></Dimmer>}>
            <EYDAppAuthWrapper></EYDAppAuthWrapper>
        </Suspense>
    
</MsalProvider>);




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
