import React, { useRef } from "react";
import { Button, Icon, Segment, Image, Card, Label } from "semantic-ui-react";
import { ImageUploadControlProps } from "./ImageUploadControlProps";
import { PhotoProps } from "./PhotoProps";
import NotificationService from "./../../Services/NotificationService";

export const ImageUploadControl: React.FunctionComponent<ImageUploadControlProps> = (props: ImageUploadControlProps) => {

    const fileInputRef = useRef(null);
    const allowedFileTypes = ["image/png", "image/jpeg"];


    let handleImageChange = (e) => {
        e.preventDefault();

        let files: Blob[] = Array.from(e.target.files);

        files.forEach(async (file, i) => {

            if (!allowedFileTypes.includes(file.type)) {
                NotificationService.showError('Please choose image files only. Supported image file types: JPEG / PNG.', "OK");
                return;
            }

            var resizedFile = await resizeImg(file);

            var FR = new FileReader();
            FR.addEventListener("load", (e) => {
                var parts = (e.target.result as string).split(";base64,");
                var base64 = parts[1];

                props.onImageUploaded(base64);
            });

            FR.readAsDataURL(resizedFile);

        });
    };

    let handleRemoveImage = (index) => {
        props.onRemove(index);
    };

    let handleViewImage = (photoID) => {
        props.onView(photoID);
    }

    let resizeImg = async (file: Blob) => {
        let img = document.createElement("img");
        img.src = await new Promise<any>(resolve => {
            let reader = new FileReader();
            reader.onload = (e: any) => resolve(e.target.result);
            reader.readAsDataURL(file);
        });
        await new Promise(resolve => img.onload = resolve)
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        let MAX_WIDTH = props.maxWidth;
        let MAX_HEIGHT = props.maxHeight;
        let width = img.naturalWidth;
        let height = img.naturalHeight;
        if (width > height) {
            if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
            }
        } else {
            if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
            }
        }
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        let result = await new Promise<Blob>(resolve => { canvas.toBlob(resolve, file.type, 0.95); });
        return result;
    }


    return (
        <div className="image-uploader">
            {!props.isReadonly
                && <div>
                    <Button as='div' fluid labelPosition='left' onClick={() => { if (!props.error) { (fileInputRef.current as any).click() } }}>
                        <Label as='a' className="fluid" basic pointing='right' content={props.uploadBtnLabel} color={props.error ? 'red' : null} />
                        <Button icon="upload" color={props.error ? 'red' : null} />
                    </Button>
                    <input
                        hidden
                        ref={fileInputRef}
                        type="file"
                        onChange={handleImageChange}
                    />
                </div>}
            {!props.isReadonly && props.errorMsg && props.error == true && <Label basic color='red' pointing content={props.errorMsg} />}

            <Segment basic>
                <Card.Group size="medium" >
                    {
                        props.displayImg.map((imageData: PhotoProps, index: number) => {
                            return !imageData.isRemoved &&
                                (
                                    <Card>
                                        <Image src={imageData?.url?.length > 0 ? imageData?.url : "data:image/png;base64," + imageData.data} />

                                        <Card.Content extra>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                {props.enableViewDetails &&
                                                    ((imageData.photoID === null || imageData.photoID === "") ? <div></div> : (
                                                        <div className="view-btn" onClick={() => handleViewImage(imageData.photoID)}>
                                                            <a><Icon name='expand arrows alternate' color="green" /> View Details </a>
                                                        </div>
                                                    ))
                                                }
                                                <div className="remove-btn" onClick={() => handleRemoveImage(index)}>
                                                    <a><Icon name='remove circle' color="red" /> Remove </a>
                                                </div>
                                            </div>
                                        </Card.Content>
                                    </Card>
                                )
                        }
                        )
                    }
                </Card.Group>
            </Segment>
        </div>
    )
};