﻿import React from "react";
import { Dimmer, DimmerProps, Loader } from "semantic-ui-react";

export const LoaderWrapper: React.FunctionComponent<DimmerProps> = (props) => {
    return (
        <div>
            <Dimmer style={{display:"flex", justifyContent:"center", alignItems:"center"}} {...props}>
                <Loader content={"Loading..."} />
            </Dimmer>
            {props.children}
        </div>
    );
};