export class DEListViewBase {
    constructor() {
        this.id = "";
        this.name = "";
        this.appViewID = "";
        this.formViewID = "";
        this.sortByCustomFieldID = "";
        this.sortAscending = true;
    }
}
