import { SemanticICONS } from "semantic-ui-react";

type NavigationObserver = () => void;

interface NavigationItem {
    view: JSX.Element;
    viewTitle: string;
    path: string;
    routeOnly?: boolean;
    menuOnly?: boolean;
    iconName?: SemanticICONS;
}

interface NavigationGroup {
    name: string;
    area: string;
    items: NavigationItem[];
}

interface NavigationInfo {
    pageTitle: string;
    setTitle: (setPageTitle: string) => void;
    navigationSubject: NavigationSubject;
    onBackButtonClick: () => void;
}

class NavigationSubject {

    private observer: NavigationObserver=null;

    public attach(observerToAttach: NavigationObserver): void {
        this.observer = observerToAttach;
    }

    public detach(): void {
        this.observer=null;
    }
    
    public notify(): void {
        if(this.observer!=null){
        this.observer();
        }
    }
}

const navigationSubject = new NavigationSubject();
export { navigationSubject,NavigationObserver, NavigationSubject, NavigationInfo, NavigationItem, NavigationGroup};
