import React from "react";
import { useState } from "react";
import { Icon, Input, Segment } from "semantic-ui-react";
import { debounce } from "../../../Utility/utility";
import { ItemCard } from "../ItemCard";
import { ItemsPerPageDropdown } from "../ItemsPerPageDropdown";
import { SortingPropertiesDropdown } from "../SortingPropertiesDropdown";
import { ControlledDynamicItemsViewerProps } from "./ControlledDynamicItemsViewerProps";

export const ControlledItemsCardView = <T,>(props: ControlledDynamicItemsViewerProps<T>) => {
    const { properties, items } = props;
    const [arrowDirection, setArrowDirection] = useState<any>("arrow down");

    const getNumberOfPages = () => {
        return props.pagination ? Math.ceil(props.numberOfItems / props.numberOfItemsShown) : 1;
    };

    return (
        <div>
            <Segment attached={props.attached}>
                <div className="items-view-row items-view-flex-row">
                    <div className="items-view-flex-item">
                        <SortingPropertiesDropdown
                            setSortingOrder={(newState: any) => setArrowDirection(newState)}
                            setSortingProperty={(newState: keyof T) => props.onSortingPropertyChanged(newState)}
                            properties={properties}
                        />
                    </div>
                    <div className="items-view-flex-item">
                        <Icon
                            name={arrowDirection}
                            size="big"
                            onClick={() => {
                                if (arrowDirection === "arrow down") {
                                    setArrowDirection("arrow up");
                                    props.onSortingDirectionChanged("ascending");
                                } else {
                                    setArrowDirection("arrow down");
                                    props.onSortingDirectionChanged("descending");
                                }
                            }}
                            onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                            }}
                        />
                    </div>
                </div>
                {(properties.some((pr) => pr.searchable) || props.pagination || props.customTools) && (
                    <div className="items-view-row items-view-flex-row">
                        <div className="items-view-flex-item">
                            <ItemsPerPageDropdown
                                noOfItemsToShowArray={props.noOfItemsToShowArray}
                                setItemsPerPage={(newState: number) => {
                                    props.onNumberOfItemsShownChanged(newState);
                                    if (props.onActivePageChanged) {
                                        props.onActivePageChanged(1);
                                    }
                                }}
                                selectedValue={props.numberOfItemsShown}
                            />
                        </div>
                        {props.pagination && getNumberOfPages() >= 2 && (
                            <div className="items-view-flex-item">
                                <Icon
                                    size="large"
                                    name="angle left"
                                    onClick={() => {
                                        if (props.activePage > 1) {
                                            props.onActivePageChanged(props.activePage - 1);
                                        }
                                    }}
                                    onMouseOver={(e) => {
                                        e.target.style.cursor = "pointer";
                                    }}
                                />
                                {props.activePage} / {props.pagination ? Math.ceil(items.length / props.numberOfItemsShown) : 1}
                                <Icon
                                    size="large"
                                    name="angle right"
                                    onClick={() => {
                                        if (props.activePage < getNumberOfPages()) {
                                            props.onActivePageChanged(props.activePage + 1);
                                        }
                                    }}
                                    onMouseOver={(e) => {
                                        e.target.style.cursor = "pointer";
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
                <div className="items-view-row items-view-flex-item">
                    <Input
                        fluid
                        icon="search"
                        placeholder="Search..."
                        onChange={debounce((event, data) => {
                            props.onSearchKeyWordChanged(data.value);
                            if (props.onActivePageChanged) {
                                props.onActivePageChanged(1);
                            }
                        })}
                    />
                </div>
                <div className="items-view-row items-view-flex-item">
                    {props.customTools &&
                        props.customTools.map((el) => {
                            return <div className="items-view-flex-item">{el}</div>;
                        })}
                </div>
            </Segment>

            {items.map((item, index) => {
                return <ItemCard item={item} key={index} properties={properties} itemActions={props.itemActions} />;
            })}
        </div>
    );
};
