import {
    DynamicEntityFieldType,
    DynamicEntityQueryFilter,
    DynamicEntityQueryFilterType,
    FieldTargetType,
    CustomFieldBase,
    LookupTableValueBase
} from "eyam-webui-models";

import { useEffect, useState } from "react";
import { Button, Checkbox, Divider, Dropdown, Form, Icon, Input, Label, Segment } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import React from "react";
import { useTranslation } from "react-i18next";

class Filter extends DynamicEntityQueryFilter {
    [key: string]: any;
}

interface QueryFilterControlProps {
    customFields: CustomFieldBase[];
    lookupTablesValues: LookupTableValueBase[];
    onChange: (value: DynamicEntityQueryFilter) => any;
    queryFilter?: Filter;
    showError?: boolean;
}

const isFilterValid = (filter: Filter) => {
    if (filter) {
        const isLogicGate = filter.filterType === DynamicEntityQueryFilterType.AND || filter.filterType === DynamicEntityQueryFilterType.OR;
        if (isLogicGate) {
            if (filter.operands?.length > 1) {
                for (const op of filter.operands) {
                    if (isFilterValid(op) === false) {
                        return false;
                    }
                }
                return true;
            }
            return false;
        }

        return filter.operandID && filter.operandValue ? true : false;
    }
    return true;
};

const OperationsMapper = (filterType: DynamicEntityQueryFilterType): string => {
    switch (filterType) {
        case DynamicEntityQueryFilterType.AND:
            return "And";
        case DynamicEntityQueryFilterType.OR:
            return "Or";
        case DynamicEntityQueryFilterType.LIKE:
            return "Like";
        case DynamicEntityQueryFilterType.EQ:
            return "=";
        case DynamicEntityQueryFilterType.GT:
            return ">";
        case DynamicEntityQueryFilterType.LT:
            return "<";
        case DynamicEntityQueryFilterType.GTE:
            return ">=";
        case DynamicEntityQueryFilterType.LTE:
            return "<=";
        case DynamicEntityQueryFilterType.IN:
            return "In";
        default:
            return "";
    }
};

const QueryFilterControl: React.FunctionComponent<QueryFilterControlProps> = (props) => {
    const { t } = useTranslation();
    const [queryFilter, setQueryFilter] = useState<Filter>(props.queryFilter ? props.queryFilter : null);

    useEffect(() => {
        props.onChange(queryFilter);
    }, [queryFilter]);

    useEffect(() => {
        if (props.queryFilter) {
            setQueryFilter(props.queryFilter);
        }
    }, [props.queryFilter]);

    const renderFilterValue = (qf: DynamicEntityQueryFilter) => {
        const customField = props.customFields.find((cf) => cf.customFieldID === qf.operandID) || new CustomFieldBase();
        const placeholder = t("GENERIC_LABEL-PLACEHOLDER_VALUE");
        return customField.fieldType === DynamicEntityFieldType.Lookup || customField.fieldType == DynamicEntityFieldType.Array ? (
            qf.filterType === DynamicEntityQueryFilterType.IN ? (
                <Dropdown
                    multiple
                    fluid
                    search
                    options={
                        props.lookupTablesValues
                            ?.filter((l) => l.lookupTableID === customField.lookupTableID)
                            ?.map((l, ind) => ({ key: ind, text: t(l.localizedKey, l.name), value: l.id })) || []
                    }
                    onChange={(_, { value }) => {
                        qf.operandValue = (value as string[]).join(",");
                        setQueryFilter({ ...queryFilter });
                    }}
                    value={qf.operandValue?.length > 0 ? qf.operandValue.split(",") : []}
                    selection
                    placeholder={placeholder}
                />
            ) : (
                <Dropdown
                    // fluid
                    options={
                        props.lookupTablesValues
                            ?.filter((l) => l.lookupTableID === customField.lookupTableID)
                            ?.map((l, ind) => ({ key: ind, text: t(l.localizedKey, l.name), value: l.id })) || []
                    }
                    onChange={(_, { value }) => {
                        qf.operandValue = value as string;
                        setQueryFilter({ ...queryFilter });
                    }}
                    value={qf.operandValue}
                    selection
                    placeholder={placeholder}
                />
            )
        ) : customField.fieldType === DynamicEntityFieldType.Boolean ? (
            <Dropdown
                options={[
                    {
                        key: true,
                        text: t("GENERIC_LABEL-YES"),
                        value: "true"
                    },
                    {
                        key: false,
                        text: t("GENERIC_LABEL-NO"),
                        value: "false"
                    }
                ]}
                onChange={(_, { value }) => {
                    qf.operandValue = value as string;
                    setQueryFilter({ ...queryFilter });
                }}
                value={qf.operandValue}
                selection
                placeholder={placeholder}
            />
        ) : customField.fieldType === DynamicEntityFieldType.Date ? (
            <DateInput
                value={qf.operandValue}
                onChange={(e, data) => {
                    qf.operandValue = data.value;
                    setQueryFilter({ ...queryFilter });
                }}
                dateFormat={"YYYY-MM-DD"}
                placeholder={placeholder}
            />
        ) : (
            <Input
                placeholder={placeholder}
                onChange={(_, { value }) => {
                    qf.operandValue = value as string;
                    setQueryFilter({ ...queryFilter });
                }}
                value={qf.operandValue}
            />
        );
    };

    const renderQueryFilter = (qf: Filter) => {
        const isLogicGate = qf.filterType === DynamicEntityQueryFilterType.AND || qf.filterType === DynamicEntityQueryFilterType.OR;
        return (
            <Segment className="query-filter">
                <div className="query-item">
                    <Dropdown
                        // fluid
                        placeholder={t("GENERIC_LABEL-PLACEHOLDER_FILTER_TYPE")}
                        options={
                            [
                                DynamicEntityQueryFilterType.AND,
                                DynamicEntityQueryFilterType.OR,
                                DynamicEntityQueryFilterType.LIKE,
                                DynamicEntityQueryFilterType.EQ,
                                DynamicEntityQueryFilterType.GT,
                                DynamicEntityQueryFilterType.GTE,
                                DynamicEntityQueryFilterType.LT,
                                DynamicEntityQueryFilterType.LTE,
                                DynamicEntityQueryFilterType.IN
                            ].map((filtertype) => {
                                const label = OperationsMapper(filtertype);
                                return {
                                    key: label + filtertype,
                                    text: label,
                                    value: filtertype
                                };
                            }) || []
                        }
                        onChange={(_, { value }) => {
                            let filterType = value as DynamicEntityQueryFilterType
                            qf.filterType = filterType;
                            if (filterType === DynamicEntityQueryFilterType.AND || filterType === DynamicEntityQueryFilterType.OR) {
                                qf.operandID = "";
                                qf.operandValue = "";
                            }
                            setQueryFilter({ ...queryFilter });
                        }}
                        selection
                        value={qf.filterType}
                    />
                </div>
                {!isLogicGate && (
                    <>
                        <div className="query-item">
                            <Dropdown
                                // fluid
                                options={
                                    props.customFields?.map((c, index) => ({
                                        key: c.customFieldID + index,
                                        text: t(c.customFieldLocalizedKey, c.customFieldName),
                                        value: c.customFieldID
                                    })) || []
                                }
                                onChange={(_, { value }) => {
                                    qf.operandID = value as string;
                                    qf.operandValue = "";
                                    setQueryFilter({ ...queryFilter });
                                }}
                                selection
                                placeholder={t("GENERIC_LABEL-PLACEHOLDER_CUSTOM_FIELD")}
                                value={qf.operandID}
                            />
                        </div>
                        <div className="query-item">{renderFilterValue(qf)}</div>
                    </>
                )}
                {isLogicGate && (
                    <>
                        {qf.operands?.map((operand, index) => {
                            return (
                                <Segment style={{ borderLeftWidth: "4px" }} key={"operand_" + index} className="query-item flex-item">
                                    <div>
                                        <Button
                                            style={{ marginRight: "10px" }}
                                            icon="remove"
                                            color="red"
                                            size="mini"
                                            onClick={() => {
                                                qf.operands = qf.operands.filter((f) => f !== operand);
                                                setQueryFilter({ ...queryFilter });
                                            }}
                                        />
                                    </div>
                                    {renderQueryFilter(operand)}
                                </Segment>
                            );
                        })}
                        <Button
                            icon="plus"
                            color="green"
                            style={{ marginTop: "10px" }}
                            size="mini"
                            onClick={() => {
                                const newFilter = new Filter();
                                newFilter.key = new Date().getTime();
                                qf.operands.push(newFilter);
                                setQueryFilter({ ...queryFilter });
                            }}
                        />
                    </>
                )}
            </Segment>
        );
    };

    return (
        <div className="query-form">
            {queryFilter ? (
                <>
                    {props.showError && !isFilterValid(queryFilter) && (
                        <Label basic color="red" pointing="below">
                            {t("GENERIC_ERROR_ALERT-FIELD_VALIDATION_QUERY_FILTER")}
                        </Label>
                    )}

                    <Segment className="flex-item">
                        <div>
                            <Button
                                style={{ marginRight: "10px" }}
                                icon="remove"
                                color="red"
                                size="mini"
                                onClick={() => setQueryFilter(null)}
                            />
                        </div>
                        {renderQueryFilter(queryFilter as DynamicEntityQueryFilter)}
                    </Segment>
                </>
            ) : (
                <Button
                    icon="plus"
                    color="green"
                    size="mini"
                    onClick={() => {
                        setQueryFilter(new DynamicEntityQueryFilter());
                    }}
                />
            )}
        </div>
    );
};

export { QueryFilterControl, QueryFilterControlProps, DynamicEntityQueryFilter, isFilterValid };
