import AppHeader from "./AppHeader";
import { useMediaQuery } from "react-responsive";
import NavigationMenu from "./NavigationMenu";
import { deviceMediaQuery } from "../Utility/utility";
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthContext } from "../Auth/AuthContext";
import { AuthSwitch } from "../Auth/AuthSwitch";
import { NavigationGroup } from "../Navigation/NavigationUtils";
import { Loader } from "semantic-ui-react";
import { ErrorBoundary } from "../Utility/ErrorBoundary";
import { LoaderWrapper } from "../Utility/LoaderWrapper";

interface MainContainerProps {
    navigationConfig: NavigationGroup[];
    areaAccessRules: { [key: string]: string[] };
    authRoles:  string[];
    notAllowedRoute: string;
    renderNotAllowedPage: () => JSX.Element;
    notFoundRoute: string;
    renderNotFoundPage: () => JSX.Element;
    homeRoute: string;
    renderSceneError?: () => JSX.Element;
    logSceneBoundaryError?: (error: any, errorInfo: any) => void;
    onLogout?: () => any;
    sidebarWidth?: number;
    appLogoPath: string;
    isProcessing?: boolean;
}

export const MainContainer: React.FunctionComponent<MainContainerProps> = (props: MainContainerProps) => {
    const [isNavMenuVisible, setVisible] = React.useState(false);
    const isTabletOrMobile = useMediaQuery({ query: deviceMediaQuery });


    return (
        <AuthContext.Consumer>
            {(authData) => {

                let defaultRoute = props.homeRoute;

                return (
                    <div className={"main-container " + (isTabletOrMobile ? "device-layout" : "desktop-layout")}>
                        <NavigationMenu
                            sidebarWidth={props.sidebarWidth}
                            navigationConfig={props.navigationConfig}
                            visible={isNavMenuVisible}
                            onHide={() => setVisible(false)}
                            onLogout={props.onLogout}
                            authAccessRules={props.areaAccessRules}
                            authRoles={props.authRoles}
                        />
                            <div className="main-content">
                            <AppHeader onNavClick={() => setVisible(true)} appLogoPath={props.appLogoPath} homeRoute={props.homeRoute}/>
                            <div className="scene">
                                <ErrorBoundary logError={props.logSceneBoundaryError} renderError={props.renderSceneError} >
                                <Suspense fallback={<Loader active inline />}>
                                    <Routes>
                                        {defaultRoute && defaultRoute != "" && defaultRoute != "/" && <Route path={"*"} element={<Navigate to={defaultRoute}/>} />}
                                        {props.navigationConfig.map((navGroup, index) => {
                                            let key = "group-" + index;
                                            return navGroup.items.map((item, index) => {
                                                if (item.menuOnly) {
                                                    return;
                                                }
                                                return (
                                                <Route
                                                    key={key + index}
                                                    path={item.path}
                                                    element={
                                                        <ErrorBoundary key={"error-bound-" + index} logError={props.logSceneBoundaryError} renderError={props.renderSceneError} >
                                                            <AuthSwitch
                                                                renderAllowed={() => <LoaderWrapper inverted active={ props.isProcessing}>{item.view}</LoaderWrapper>}
                                                                renderDenied={ props.renderNotAllowedPage }                                
                                                                area={navGroup.area}
                                                                roles={authData.userProfile.roles}
                                                                areaAccessRules={props.areaAccessRules}
                                                            />
                                                        </ErrorBoundary>
                                                    }
                                                />
                                            )});
                                        })}
                                        <Route path={props.notAllowedRoute} element={props.renderNotAllowedPage()} />
                                        <Route element={props.renderNotFoundPage()} />
                                    </Routes>
                                    </Suspense>
                                </ErrorBoundary>
                            </div>
                        </div>
                    </div>
                );
            }}
        </AuthContext.Consumer>
    );
};
