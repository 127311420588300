import React, { useEffect, useState } from "react";
import { ItemsCardView } from "./ItemsCardView";
import { ItemsTableView } from "./ItemsTableView";
import { DynamicItemsViewerProps } from "./DynamicItemsViewerProps";


export const DynamicItemsViewer = <T,>(props: DynamicItemsViewerProps<T>) => {
    
    const [screenSize, setScreenSize] = useState<number>(window.innerWidth);
   
    useEffect( () => {
        const handleResize = () => setScreenSize(window.innerWidth);
        window.addEventListener('resize', handleResize);
    },[])

    return(
        <div className= "dynamic-items-viewer">
            {
                screenSize > props.cutoffScreenSize ? <ItemsTableView    {... props} /> : 
                                                      <ItemsCardView {... props}/>
            }
        </div>
    )
}


