import { DynamicEntityQueryFilter } from "./DynamicEntityQueryFilter";
import { DynamicEntityQueryFilterType } from "./DynamicEntityQueryFilterType";
function buildSearchQF(searchKeyword, searchFields) {
    if (!searchKeyword || !searchFields || searchFields.length === 0) {
        return null;
    }
    if (searchFields.length === 1) {
        return { filterType: DynamicEntityQueryFilterType.LIKE, operandID: searchFields[0], operandValue: searchKeyword, operands: [] };
    }
    const qf = new DynamicEntityQueryFilter();
    qf.filterType = DynamicEntityQueryFilterType.OR;
    for (const field of searchFields) {
        qf.operands.push({ filterType: DynamicEntityQueryFilterType.LIKE, operandID: field, operandValue: searchKeyword, operands: [] });
    }
    return qf;
}
export { buildSearchQF };
