import React from "react";
import { Button, ButtonProps, Dimmer, Grid, Loader, Message, Modal } from "semantic-ui-react";
import { DynamicForm } from "../..";
import { FormFieldGroup } from "./FormFieldGroup";

export interface DynamicModalFormProps {
    open: boolean,
    title: string,
    errorMessage?: string,
    modalFormGroups: FormFieldGroup[],
    onOpenHandler?: () => void,
    onCloseHandler?: () => void,   
    formActions?: DynamicModalFormAction[],
    customContent?: JSX.Element,
    isProcessing?: boolean,
}

export interface DynamicModalFormAction extends ButtonProps {
    key: string,
    content: string
    onClick: () => void
}

export const DynamicModalForm = (props: DynamicModalFormProps) => {
    return (
        <div>
            <Modal className="form-dialog-modal" onClose={props.onCloseHandler} onOpen={props.onOpenHandler} open={props.open} closeOnDocumentClick={false} closeOnEscape={false} closeOnDimmerClick={false}>
                <Modal.Header>{props.title}</Modal.Header>
                <Modal.Content>
                    {props.isProcessing? (
                        <Dimmer active inverted>
                            <Loader content="Loading" inline="centered" active />
                        </Dimmer>

                    ) : (
                        <>
                         {props.customContent != null ? props.customContent : ''}
                        {props.errorMessage && <Message negative>
                            <Message.Header>Error</Message.Header>
                                <p>{props.errorMessage}</p>
                            </Message>}
                            <DynamicForm groups={props.modalFormGroups}></DynamicForm>
                        </>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    
                            {
                                props.formActions &&
                                props.formActions.map((action) => {                                  
                                        return (
                                            <Button
                                                {...action}
                                            />
                                        );
                                    })    
                            }                               
                       
                </Modal.Actions>
            </Modal>
        </div>
    )
}

