import React from "react";
import { AuthContext } from "../Auth/AuthContext";
import { Icon, Grid, Header, Image } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { deviceMediaQuery } from "../Utility/utility";
import { useNavigationContext } from "../Navigation/NavigationContext";
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';

interface AppHeaderHeaderProps {
    onNavClick?: () => any;
    appLogoPath?: string;
    homeRoute?: string;
}

const AppHeader: React.FunctionComponent<AppHeaderHeaderProps> = (props: AppHeaderHeaderProps) => {
    const isTabletOrMobile = useMediaQuery({ query: deviceMediaQuery });
    const navigationContext = useNavigationContext();
    const pageTitle = navigationContext.pageTitle;
    const pageTitleHeader = (pageTitle && pageTitle != "HOME") && <div className="asset-manager-header__tablet-page-title">{pageTitle}</div>;
    const location = useLocation();
    
    const onBackButtonClick=()=>{
        if(navigationContext.onBackButtonClick()!=null)
        {
            navigationContext.navigationSubject.notify();
        }
    }
    
    return (
        <AuthContext.Consumer>
            {(authData) => {
                return (
                    <div className="asset-manager-header">
                        {isTabletOrMobile ? (
                            <Grid verticalAlign="middle" columns="equal">
                                <Grid.Row>
                                    <Grid.Column floated="left" width={10}>
                                        <div className="asset-manager-header__brand-container">
                                        <NavLink to={props.homeRoute}><Image className="asset-manager-header__app-logo" src={props.appLogoPath}/></NavLink>                                             <div className="asset-manager-header__title-container">
                                                <div className="asset-manager-header__title-top">
                                                    COInS
                                                </div>
                                                <div className="asset-manager-header__title-bottom">
                                                    Field Asset Module
                                                </div> 
                                            </div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column floated="right" width={6} textAlign={"right"}>
                                        {location.pathname != props.homeRoute && <Icon
                                            size="large"
                                            name="arrow left"
                                            className="asset-manager-header__nav-button"
                                            onClick={()=>{onBackButtonClick()}}
                                        />}
                                        <Icon
                                            size="large"
                                            onClick={props.onNavClick}
                                            name="bars"
                                            className="asset-manager-header__nav-button"
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                {pageTitleHeader &&

                                    <Grid.Row className="asset-manager-header__mobile-page-title-container">
                                        <Grid.Column floated="left" className="asset-manager-header__mobile-page-title">
                                            <div style={{marginBottom: "1.5rem"}} className="ui horizontal divider">{pageTitle}</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                }

                            </Grid>
                        ) : (
                            <Grid verticalAlign="middle" columns="equal">
                                <Grid.Row>
                                    <Grid.Column className="asset-manager-header__title">{pageTitleHeader}</Grid.Column>
                                    <Grid.Column textAlign="right" className="asset-manager-header__user-info" floated="right">
                                        <Header size="small">
                                                <Header.Content>
                                                    {authData.userProfile.displayName}
                                            </Header.Content>
                                            <Icon name="user circle" fitted size="big"/>
                                        </Header>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        )}
                    </div>
                );
            }}
        </AuthContext.Consumer>
    );
};

export default AppHeader;
