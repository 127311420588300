import { PhotoDetailsDisplayProps } from "./PhotoDetailsDisplayProps";
import React, { useEffect, useRef, useState } from "react";
import { DynamicItemsViewer } from "../DynamicItemsViewer/DynamicItemsViewer";
import { PhotoBase, PhotoDetailBase } from "eyam-webui-models";
import { useTranslation } from "react-i18next";

export const PhotoDetailsDisplayControl: React.FunctionComponent<PhotoDetailsDisplayProps> = (props: PhotoDetailsDisplayProps) => {
    const { t, i18n } = useTranslation();

    const photoCanvas = useRef(null);
    const canvasWrapper = useRef(null);

    useEffect(() => {
        handleImageDisplay(props.selectedPhoto, null);
    }, [photoCanvas]);

    const handleImageDisplay = (photo: PhotoBase, photoDetail: PhotoDetailBase) => {
        let canvas = photoCanvas?.current;
        let ctx = canvas?.getContext("2d");

        const maxW = canvasWrapper?.current.parentElement.clientWidth;
        const maxH = maxW;
        const image = new Image();

        image.src = photo?.url?.length > 0 ? photo?.url : "data:image/png;base64," + photo.data;
        image.onload = () => {
            const iw = image.width;
            const ih = image.height;
            const scale = Math.min((maxW / iw), (maxH / ih));
            const iwScaled = iw * scale;
            const ihScaled = ih * scale;
            canvas.width = iwScaled;
            canvas.height = ihScaled;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(image, 0, 0, iwScaled, ihScaled);

            if (photoDetail) {
                let left = photoDetail.left * scale;
                let top = photoDetail.top * scale;
                let width = photoDetail.width * scale;
                let height = photoDetail.height * scale;
        
                ctx.beginPath();
                ctx.lineWidth = 3;
                ctx.strokeStyle = "yellow";
                ctx.rect(left, top, width, height);
                ctx.stroke();
            }
        };
    };

    const handleDetailClick = async (photoDetail: PhotoDetailBase) => {
        handleImageDisplay(props.selectedPhoto, photoDetail);
    };

    return (
        <div className="photo-modal-content">
        <div ref={canvasWrapper} className="canvas-wrapper">
            <canvas ref={photoCanvas} className="modal-photo" />
        </div>
        <div className="modal-photo-details">
            <DynamicItemsViewer
                items={props.selectedPhotoDetails}
                pagination={false}
                
                properties={[
                    {
                        key: "tagId",
                        label: t("PAGE_PHOTO_DETAILS-TAG_ID"),
                        searchable: true,
                        displayPlaces: ["cardHeader", "table"]
                    },
                    {
                        key: "tagName",
                        label: t("PAGE_PHOTO_DETAILS-TAG_NAME"),
                        searchable: true,
                        displayPlaces: ["cardHeader", "table"]
                    },
                    {
                        key: "tagType",
                        label: t("PAGE_PHOTO_DETAILS-TAG_TYPE"),
                        searchable: true,
                        displayPlaces: ["cardHeader", "table"]
                    },
                    {
                        key: "description",
                        label: t("PAGE_PHOTO_DETAILS-DESCRIPTION"),
                        searchable: true,
                        displayPlaces: ["cardHeader", "table"]
                    },
                    {
                        key: "probability",
                        label: t("PAGE_PHOTO_DETAILS-PROBABILITY"),
                        searchable: true,
                        displayPlaces: ["cardHeader", "table"]
                    }
                ]}
                onItemsClick={handleDetailClick}
                cutoffScreenSize={0} />
        </div>
    </div>
    )
};