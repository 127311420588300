export var TriggerEvent;
(function (TriggerEvent) {
    TriggerEvent[TriggerEvent["AssetCreated"] = 1] = "AssetCreated";
    TriggerEvent[TriggerEvent["AssetUpdated"] = 2] = "AssetUpdated";
    TriggerEvent[TriggerEvent["AssetDeleted"] = 3] = "AssetDeleted";
    TriggerEvent[TriggerEvent["ItemMasterDetailCreated"] = 4] = "ItemMasterDetailCreated";
    TriggerEvent[TriggerEvent["ItemMasterDetailUpdated"] = 5] = "ItemMasterDetailUpdated";
    TriggerEvent[TriggerEvent["ItemMasterDetailDeleted"] = 6] = "ItemMasterDetailDeleted";
    TriggerEvent[TriggerEvent["WorkflowInstanceCreated"] = 7] = "WorkflowInstanceCreated";
    TriggerEvent[TriggerEvent["WorkflowInstanceUpdated"] = 8] = "WorkflowInstanceUpdated";
    TriggerEvent[TriggerEvent["WorkflowInstanceDeleted"] = 9] = "WorkflowInstanceDeleted";
})(TriggerEvent || (TriggerEvent = {}));
