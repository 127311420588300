export var ListLogicalOperationType;
(function (ListLogicalOperationType) {
    ListLogicalOperationType[ListLogicalOperationType["AND"] = 0] = "AND";
    ListLogicalOperationType[ListLogicalOperationType["OR"] = 1] = "OR";
    ListLogicalOperationType[ListLogicalOperationType["LIKE"] = 2] = "LIKE";
    ListLogicalOperationType[ListLogicalOperationType["EQ"] = 3] = "EQ";
    ListLogicalOperationType[ListLogicalOperationType["GT"] = 4] = "GT";
    ListLogicalOperationType[ListLogicalOperationType["LT"] = 5] = "LT";
    ListLogicalOperationType[ListLogicalOperationType["GTE"] = 6] = "GTE";
    ListLogicalOperationType[ListLogicalOperationType["LTE"] = 7] = "LTE";
    ListLogicalOperationType[ListLogicalOperationType["IN"] = 8] = "IN";
})(ListLogicalOperationType || (ListLogicalOperationType = {}));
