export class DataExcelConfiguration {
    constructor() {
        this.lookupTables = true;
        this.lookupTableValues = true;
        this.customFields = true;
        this.viewGroups = true;
        this.appViews = true;
        this.formViews = true;
        this.formCustomFieldAssociations = true;
        this.listViews = true;
        this.listCustomFieldAssociations = true;
        this.listOperationNodes = true;
        this.customFieldDependencies = true;
        this.lookupDependencies = true;
        this.businessLocations = true;
        this.assets = true;
        this.inventoryItems = true;
        this.quantityValues = true;
        this.externalTriggers = true;
        this.triggersFieldAssociations = true;
        this.externalTriggerHeaders = true;
        this.globalSettings = true;
        this.localizationLanguages = true;
        this.localizationTypes = true;
        this.localizationToken = true;
        this.itemMasterDetails = true;
        this.itemCountSessions = true;
        this.itemCounts = true;
        this.itemCountDetails = true;
        this.processOrders = true;
        this.processOrderItems = true;
        this.workflows = false;
        this.workflowSteps = false;
        this.workflowTransitions = false;
        this.workflowInstances = false;
        this.approvalRequests = false;
        this.approvalRequestWorkflows = false;
    }
}
