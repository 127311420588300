import React, { useContext } from "react";
import { RuntimeInfo } from "./RuntimeInfo";

export const RuntimeContext = React.createContext<RuntimeInfo>({
    language: "",
	setLanguage: (_) => {},
	darkMode: false,
	toggleDarkMode: () => {},
	isProcessing: false,
	setIsProcessing: () => {}
});

export const useRuntimeContext = () => useContext(RuntimeContext);
