import React from "react";
import { useEffect, useState } from "react";
import { Input, Pagination, PaginationProps, Segment, Table } from "semantic-ui-react";
import { ItemDisplayConfig } from "./ItemDisplayConfig";
import { debounce } from "../../Utility/utility";
import { ItemsPerPageDropdown } from "./ItemsPerPageDropdown";
import { DynamicItemsViewerProps } from "./DynamicItemsViewerProps";
import { searchArray } from "../../Utility/SearchUtil";
import { sortArray } from "../../Utility/SortUtil";
import { CustomTableHead } from "./CustomTableHead";
import { CustomTableRow } from "./CustomTableRow";

export const ItemsTableView = <T,>(props: DynamicItemsViewerProps<T>) => {
    const { properties, items, itemActions } = props;
    const [filteredItems, setFilteredItems] = useState<T[]>(items as T[]);
    const [searchKeyWord, setSearchKeyWord] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [activePage, setActivePage] = useState(1);
    const [sortingProperty, setSortingProperty] = useState<keyof T>();
    const [sortingDirection, setSortingDirection] = useState("");

    useEffect(() => {
        let fItems = filterItems(items, searchKeyWord, props.properties);
        let sortedItems = sortItems(fItems);
        setFilteredItems(sortedItems);
    }, [items, searchKeyWord, sortingProperty, sortingDirection]);

    const filterItems = (items: T[], query: string, itemDisplayConfigs: ItemDisplayConfig<T>[]) => {
        let fItems: T[];

        if (query && query != "") {
            let filterDisplayConfigs = itemDisplayConfigs
                .filter((col) => col.searchable)
                .map((col) => {
                    return [
                        col.key,
                        col.customRender,
                        col.formaters,
                        col.customRenderUseFormatData,
                        col.searchCriteria != null ? col.searchCriteria : "contain"
                    ];
                });

            fItems = searchArray(items, query, filterDisplayConfigs as [], false);
        } else {
            fItems = items;
        }

        return fItems;
    };

    const sortItems = (itms: T[]) => {
        let sortableColumns = props.properties.filter((col) => col.sortable).map((col) => col.key);

        if (sortingProperty == null || sortingProperty == "" || sortableColumns.indexOf(sortingProperty as keyof T) < 0) {
            return itms;
        }
        let displayConfig = props.properties.find((col) => col.key == sortingProperty);

        return sortArray(
            itms,
            sortingProperty,
            displayConfig.customRender,
            displayConfig.formaters,
            displayConfig.customRenderUseFormatData,
            sortingDirection == "ascending"
        );
    };

    const getNumberOfPages = () => {
        return props.pagination ? Math.ceil(filteredItems.length / itemsPerPage) : 1;
    };

    const handleClick = (item) => {
        if (props.onItemsClick) {
            props.onItemsClick(item);
        }
    };

    return (
        <div style={{ position: "relative" }}>
            {(properties.some((pr) => pr.searchable) || props.pagination || props.customTools) && (
                <Segment attached={props.attached}>
                    <div className="items-view-row items-view-flex-row">
                        <div className="items-view-flex-item">
                            {properties.some((pr) => pr.searchable) && (
                                <Input
                                    style={{ width: "20em" }}
                                    icon="search"
                                    placeholder="Search..."
                                    large="true"
                                    className="left floated"
                                    onChange={debounce((event, data) => {
                                        setSearchKeyWord(data.value);
                                        if (props.onSearchKeyWordChanged) {
                                            props.onSearchKeyWordChanged(data.value);
                                        }

                                        if (props.onActivePageChanged) {
                                            props.onActivePageChanged(1);
                                        }
                                        setActivePage(1);
                                    })}
                                />
                            )}
                        </div>

                        {props.customTools &&
                            props.customTools.map((el, index) => {
                                return <div key={index} className="items-view-flex-item">{el}</div>;
                            })}

                        <div className="items-view-row items-view-flex-row">
                            {props.pagination && (
                                <div className="items-view-flex-item">
                                    <ItemsPerPageDropdown
                                        noOfItemsToShowArray={props.noOfItemsToShowArray}
                                        setItemsPerPage={(newState: number) => {
                                            setItemsPerPage(newState);
                                            if (props.onNumberOfItemsShownChanged) {
                                                props.onNumberOfItemsShownChanged(newState);
                                            }
                                            if (props.onActivePageChanged) {
                                                props.onActivePageChanged(1);
                                            }
                                            setActivePage(1);
                                        }}
                                    />
                                </div>
                            )}

                            {props.pagination && (
                                <div className="items-view-flex-item">
                                    {props.pagination && (
                                        <Pagination
                                            boundaryRange={0}
                                            siblingRange={1}
                                            size="small"
                                            activePage={activePage}
                                            totalPages={getNumberOfPages()}
                                            onPageChange={(e, pageInfo: PaginationProps) => setActivePage(pageInfo.activePage as number)}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </Segment>
            )}
            <Table celled compact sortable selectable>
                <CustomTableHead
                    properties={properties}
                    onSortingColumnChanged={(sp) => {
                        setSortingProperty(sp);
                        if (props.onSortingPropertyChanged) {
                            props.onSortingPropertyChanged(sp);
                        }
                    }}
                    onSortingOrderChanged={(direction) => {
                        setSortingDirection(direction);
                        if (props.onSortingDirectionChanged) {
                            props.onSortingDirectionChanged(direction);
                        }
                    }}
                    itemActions={props.itemActions}
                />
                <Table.Body>
                    {filteredItems.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage).map((item, index) => (
                        <CustomTableRow
                            key={index}
                            item={item}
                            properties={properties}
                            handleRowClick={handleClick}
                            itemActions={itemActions}
                        />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};
