import AppHTTPClient from "./AppHTTPClient";
import { BaseService } from "./BaseService";
import { GlobalSetting } from "eyd.models-ui";
import { EYAMApiResult } from "eyam-webui-models";
import { EYAMSimpleCache } from "../UI/Utils/EYAMCache";

class GlobalSettingsService extends BaseService {
    private readonly BASE_URL: string = "/api/GlobalSettings";

    setLocalStorageCache(key, value, ttl) {
        const now = new Date();

        const cachedItem = {
            value: value,
            expiry: now.getTime() + ttl,
        }

        localStorage.setItem(key, JSON.stringify(cachedItem));
    }

    getLocalStorageCache(key) {
        const itemString = localStorage.getItem(key);

        if (!itemString) {
            return null;
        }

        const cachedItem = JSON.parse(itemString);
        const now = new Date();

        if (now.getTime() > cachedItem.expiry) {
            localStorage.removeItem(key);
            return null;
        }

        return cachedItem.value;
    }

    getAllGlobalSettings(): Promise<GlobalSetting[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<GlobalSetting[]>>(`${this.BASE_URL}/GetAllGlobalSettings`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    getGlobalSettingById(id: string): Promise<GlobalSetting> {
        return new Promise((resolve, reject) => {

            //var cachedData = EYAMSimpleCache.get<GlobalSetting>(`getGlobalSettingById/${id}`);

            var cachedData = this.getLocalStorageCache(id);

            if (cachedData != null && cachedData.settingValue.valueBoolean) {
                resolve(cachedData)
            } else {
                AppHTTPClient.get<EYAMApiResult<GlobalSetting>>(`${this.BASE_URL}/GetGlobalSettingById/${id}`)
                    .then((response) => {
                        if (response.data.value != null && response.data.value.settingValue.valueBoolean) {

                            localStorage.removeItem(id);
                            this.setLocalStorageCache(id, response.data.value, 2592000);

                        } else if (response.data.value != null && !response.data.value.settingValue.valueBoolean) {
                            this.setLocalStorageCache(id, response.data.value, 5000);
                        }

                        //EYAMSimpleCache.put(`getGlobalSettingById/${id}`, response.data.value.settingValue.valueBoolean, 1800);

                        resolve(response.data.value)
                    })
                    .catch((error) => this.handleError(error, reject));
            }
        });
    }

    addGlobalSetting(setting: GlobalSetting): Promise<string> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<EYAMApiResult<string>>(`${this.BASE_URL}/AddGlobalSetting`, setting)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    updateGlobalSetting(setting: GlobalSetting): Promise<string> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<EYAMApiResult<string>>(`${this.BASE_URL}/UpdateGlobalSetting`, setting)
                .then((response) => {
                    resolve(response.data.value);
                    localStorage.removeItem(setting.settingId);
                })
                .catch((error) => this.handleError(error, reject));
        });
    }

    deleteGlobalSetting(id: string): Promise<string> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.delete<EYAMApiResult<string>>(`${this.BASE_URL}/DeleteGlobalSetting/${id}`)
                .then((response) => {
                    resolve(response.data.value);
                    localStorage.removeItem(id);
                })
                .catch((error) => this.handleError(error, reject));
        });
    }

}

export default new GlobalSettingsService();