import AppHTTPClient from "./AppHTTPClient";
import { BaseService } from "./BaseService";
import { DataExcelConfiguration, EYAMApiResult } from "eyam-webui-models";
import { EYDJobDTO, JobExecutionLog } from "eyd.models-ui";

class UtilityService extends BaseService {
    private readonly BASE_URL: string = "/api/Utility";

    getJobExecutionLog(jobExecutionId): Promise<JobExecutionLog> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<JobExecutionLog>>(`${this.BASE_URL}/GetJobExecutionLog/${jobExecutionId}`, null)
            .then((response) => resolve(response.data.value))
            .catch((error) => this.handleError(error, reject));
        });
    }

    getLastExecutedGenerateDataJobs(): Promise<JobExecutionLog[]> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<JobExecutionLog[]>>(`${this.BASE_URL}/GetLastExecutedGenerateDataJobs/`, null)
            .then((response) => resolve(response.data.value))
            .catch((error) => this.handleError(error, reject));
        });
    }

    getAuthRules(): Promise<{[key:string]:string[]}> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.get<EYAMApiResult<{ [key: string]: string[] }>>(`${this.BASE_URL}/getAuthRules`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }
    
    cancelAccessToken(): Promise<string> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<EYAMApiResult<string>>(`${this.BASE_URL}/CancelAccessToken`)
            .then((response) => resolve(response.data.value))
            .catch((error) => this.handleError(error, reject));
        });
    }

    bootstrapEYDApp(): Promise<EYDJobDTO> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<EYAMApiResult<EYDJobDTO>>(`${this.BASE_URL}/BootstrapEYDApp`, null, {
                timeout: 120000
            })
                .then((response) => resolve(response.data.value))

                .catch((error) => this.handleError(error, reject));
        });
    }


    flushCache(): Promise<string> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<EYAMApiResult<string>>(`${this.BASE_URL}/FlushCache`)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    resetApplication(): Promise<EYDJobDTO> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<EYAMApiResult<EYDJobDTO>>(`${this.BASE_URL}/ResetApplication`, null)
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }

    generateEmptyExcel(): Promise<EYDJobDTO> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<EYAMApiResult<EYDJobDTO>>(`${this.BASE_URL}/GenerateEmptyExcel`, null, {
                timeout:120000
            })
            .then((response) => resolve(response.data.value))
                
            .catch((error) => this.handleError(error, reject));
        });
    }

    generateExcelWithData(excelConfig: DataExcelConfiguration): Promise<EYDJobDTO> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<EYAMApiResult<EYDJobDTO>>(`${this.BASE_URL}/GenerateExcelWithData`,excelConfig, {
                timeout:120000
            })
            .then((response) => resolve(response.data.value))
                
            .catch((error) => this.handleError(error, reject));
        });
    }

    async downloadExcelFile(jobExecutionId: string): Promise<any> {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);

        return new Promise((resolve, reject) => {
            AppHTTPClient.get<any>(`${this.BASE_URL}/DownloadExcelFile/${jobExecutionId}`, {
                responseType: 'blob'})
                .then(response => {
                    let objectUrl = window.URL.createObjectURL(new Blob([response.data]));
                    anchor.href = objectUrl;
                    anchor.download = 'EYD Data.xlsx';
                    anchor.click();
                    window.URL.revokeObjectURL(objectUrl);
                    resolve(response.data);
                })
                .catch((error) => this.handleError(error, reject))
        })
    }

    async downloadExcelWithData(excelConfig: DataExcelConfiguration): Promise<any> {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<any>(`${this.BASE_URL}/DownloadExcelWithData`, excelConfig, {
                responseType: 'blob',
                timeout: 3000000})
                .then(response => {
                    let objectUrl = window.URL.createObjectURL(new Blob([response.data]));
                    anchor.href = objectUrl;
                    anchor.download = 'EYD Data.xlsx';
                    anchor.click();
                    window.URL.revokeObjectURL(objectUrl);
                    resolve(response.data);
                })
                .catch((error) => this.handleError(error, reject))
        })
    }

    updateExcelData(formData: FormData): Promise<EYDJobDTO> {
        return new Promise((resolve, reject) => {
            AppHTTPClient.post<EYAMApiResult<EYDJobDTO>>(`${this.BASE_URL}/UpdateExcelData`, formData, {
                timeout: 3000000})
                .then((response) => resolve(response.data.value))
                .catch((error) => this.handleError(error, reject));
        });
    }
}
export default new UtilityService();