import React, { useEffect, useState } from "react"
import { Dropdown, DropdownItemProps, DropdownOnSearchChangeData, DropdownProps } from "semantic-ui-react"

export interface DynamicDropdownProps extends  DropdownProps {
    maxDisplayed: number;
}

export const DynamicDropdown: React.FunctionComponent<DynamicDropdownProps> = (props) => {     
    const { options, maxDisplayed, onChange, search, ...ddProps } = props;
    const maxNumberOfItems = (maxDisplayed != null && maxDisplayed > 0) ? maxDisplayed : 20;
    const renderSimpleDD: boolean = (options != null && options.length < maxNumberOfItems) ? true : false;

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [displayItems, setDisplayItems] = useState([]);

    useEffect(() => {
        if (options.length > 1) {
            let itms = options;
            if (props.value != null && props.value != '') {
                let currentOption: DropdownItemProps = options?.find(io => io.value == props.value)
                if (currentOption) {
                    itms = [...new Set([currentOption, ...itms])];
                }
            }
            else if (props.defaultValue != null && props.defaultValue != '') {

                let defaultOption: DropdownItemProps = options?.find(io => io.value == props.defaultValue)
                if (defaultOption) {
                    itms = [...new Set([defaultOption, ...itms])];
                }
            }
            setDisplayItems(sliceItems(itms));
        }
    }, [props.options, props.value])

    useEffect(() => {
        let itms: DropdownItemProps[];
        if (props.options?.length < 1) {
            return;
        }
        if (searchQuery != '') {
            itms = options.filter(op => op.text.toLocaleString().toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) > -1)
        }
        else{
            itms = options
        }
        
        if(props.value != null && props.value != ''){
            let currentOption: DropdownItemProps = options?.find(io => io.value == props.value)
            if (currentOption) {
                itms= [...new Set([currentOption, ...itms])];
            }
        }
        else if(props.defaultValue != null && props.defaultValue != ''){
            
            let defaultOption: DropdownItemProps = options?.find(io => io.value == props.defaultValue)
            if (defaultOption) {
                itms= [...new Set([defaultOption, ...itms])];
            }
        }

        setDisplayItems(sliceItems(itms));
    }, [searchQuery])

    const handleSearchChange = (e: React.SyntheticEvent<HTMLElement>, data: DropdownOnSearchChangeData) => {
        setSearchQuery(data.searchQuery);
    }

    const sliceItems = (items: DropdownItemProps[]) => {
        let resultItems :DropdownItemProps[] = [];
        
        if (items == null) { return resultItems; }      

        if (items.length > maxNumberOfItems) {
            resultItems = items.slice(0, maxNumberOfItems - 1);
            resultItems.push({
                // use a Localization Key
                key: 'MoreOptions', value: 'MoreOptions', text: 'Other  options match...',
                disabled: true,
                active: false
            })
        }
        else {
            resultItems = items;           
        }
        return resultItems;
    }

    const handleSearchPrevent = (options: DropdownItemProps[], value: string) => {
        return options;        
    }

    const handleOnChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        onChange(event, data);
        setSearchQuery(''); 
    }

    const handleOnClose = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        setSearchQuery('');    
    }


    return (
        <div>           
            {renderSimpleDD ?
                (
                    <Dropdown
                        options={options}
                        search={search}
                        onChange={handleOnChange}
                        selectOnBlur={false}
                        selectOnNavigation={false}
                        {...ddProps as DropdownProps}
                        selection={true}
                        fluid={true}
                    />
                ) :
                (
                    <Dropdown
                        options={displayItems}
                        onSearchChange={handleSearchChange}
                        search={handleSearchPrevent}
                        noResultsMessage={"No results founded."}
                        // placeholder={"Input text..."}
                        onChange={handleOnChange}
                        onClose={handleOnClose}
                        {...ddProps as DropdownProps}                       
                        selectOnBlur={false}
                        selectOnNavigation={false}
                        selection={true}
                        fluid={true}                      
                    />
                )
            }
        </div>
    )
}