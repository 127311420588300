import { SilentRequest } from "@azure/msal-browser";
import axios from "axios";
import { API_SCOPE, EYDPCA } from "../Auth/AuthConfig"

const AppHTTPClient = axios.create({
    headers: {
        //"X-XSRF-TOKEN": document.getElementById("RequestVerificationToken").getAttribute("value")
    },
    timeout: 30000
});

AppHTTPClient.interceptors.request.use(
    async (config) => {
        //add tokens, headers etc ehre

        const token = await acquireAccessToken();
        config.headers = {
            'Authorization': `Bearer ${token}`,
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

AppHTTPClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (!!error.response && !!error.response.data.error && !!error.response.data.error.message && error.response.data.error.details) {
        } else if (!!error.response && !!error.response.data.error && !!error.response.data.error.message) {
        } else if (!error.response) {
        }
        setTimeout(() => {}, 1000);
        console.log(error);
        return Promise.reject(error);
    }
);

export const acquireAccessToken = async () => {
    const activeAccount = EYDPCA.getActiveAccount();
    const accounts = EYDPCA.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        throw "User not logged in";
    }
    const request: SilentRequest = {
        scopes: [API_SCOPE],
        account: activeAccount || accounts[0]
    };

    const authResult = await EYDPCA.acquireTokenSilent(request);

    return authResult.accessToken
};

export default AppHTTPClient;
