import React, { useState, useEffect } from "react";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";

interface ItemsPerPageDropdownProps{
    noOfItemsToShowArray: DropdownItemProps[];
    setItemsPerPage(newState: number):void;
    selectedValue?: string | number | boolean | (number | string | boolean);
}

export const ItemsPerPageDropdown = (props: ItemsPerPageDropdownProps) => {

    const onChange = (event, data) =>{
        props.setItemsPerPage(data.value);
    }

    const defaultValue = props.selectedValue ? undefined : props.noOfItemsToShowArray[0]?.value;
    
    return (
        <Dropdown
            onChange={onChange}
            options={props.noOfItemsToShowArray}
            selection
            defaultValue={defaultValue}
            value={props.selectedValue}
          />
    );
}
