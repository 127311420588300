export class DEFViewCustomFieldAssociationBase {
    constructor() {
        this.id = "";
        this.customFieldID = "";
        this.formViewID = "";
        this.defaultValue = "";
        this.metadata = "";
        this.displayOrder = 0;
    }
}
