import React, { Fragment } from "react";
import { FormFieldGroup } from "./FormFieldGroup";
import { Form, Button, Modal, Header, FormFieldProps, Tab, Label, Input, Dropdown, TextArea, DropdownItemProps, Select } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";

import { isNullOrUndefined } from "../../Utility/utility";
import { DynamicDropdown } from "../DynamicDropdown/DynamicDropdown";

export interface DynamicFormsProps {
    groups: FormFieldGroup[];
}

export const DynamicForm: React.FunctionComponent<DynamicFormsProps> = (props) => {

    const getFormFieldValues = (formField: FormFieldProps): string[] => {
        let values = new Array<string>();
        let fieldValue: any = "";

        if (!isNullOrUndefined(formField.value)) {
            fieldValue = formField.value;
        } else if (!isNullOrUndefined(formField.defaultValue)) {
            fieldValue = formField.defaultValue;
        }

        switch (formField.control) {
            case Input:
            case TextArea:
                if (fieldValue?.toString().length > 0) {
                    values.push(fieldValue.toString());
                }
                break;
            case DateInput:
                if (fieldValue?.length > 0) {
                    values.push(fieldValue.toString().substr(0, 10));
                }
                break;
            case Dropdown:
            case Select:
            case DynamicDropdown:
                if (formField.multiple == true) {
                    let fieldValues = [...fieldValue] as Array<any>;
                    let fieldOptions = [...formField.options] as Array<DropdownItemProps>;
                    fieldValues.forEach(val => values.push(fieldOptions.find(o => o.value == val)?.text as string));
                } else {
                    let fieldOptions = [...formField.options] as Array<DropdownItemProps>;
                    let option = fieldOptions.find(o => o.value == fieldValue);
                    if (!isNullOrUndefined(option)) {
                        values.push(option.text as string);
                    }
                }
                break;
        }
        return values;
    };

    const renderFormFields = (formFields: FormFieldProps[]): JSX.Element => {
        return (
            <Fragment>
                {formFields?.map((formField, index) => {
                    if (formField.disabled == true) {
                        let values = getFormFieldValues(formField);
                        return (
                            <Form.Field key={"field " + index}>
                                <label>{formField.label as string}</label>
                                {values?.length > 0 ? values.map((value, indexV) => (
                                    <Label basic size="large" key={"label " + indexV}>{value}</Label>
                                )) : <Label basic size="large">-</Label>}
                            </Form.Field>
                        );
                    } else {
                        return (
                            <Form.Field {...formField} key={"field " + index}></Form.Field>
                        );
                    }
                })}
            </Fragment>
        );
    };

    const renderFormFieldGroups = () => {
        let groups = props.groups.map((group, index) => {
            let formFields = renderFormFields(group.formFields);
            return (
                <Form.Group key={"group " + index}
                    grouped={group.groupProps?.grouped ?? true }
                    {...group.groupProps}                             
                > {formFields}
                </Form.Group>
                );
        })

        return groups;
    }

    const renderTabPanes = (): JSX.Element => {
        let panes = props.groups.map((pane, index) => {
            let formFields = pane.formFields?.map((formField, fieldIndex) => {
                if (formField.error) {
                    pane.tabPane = { ...pane.tabPane, className: "error" };
                }
                return <Form.Field key={"field " + fieldIndex} {...formField}></Form.Field>;
            });
            return {
                menuItem: { ...pane.tabPane, key: "tab " + index },
                render: () => <Tab.Pane key={"tab " + index}>{formFields}</Tab.Pane>
            };
        });
        return <Tab panes={panes} />;
    };

    if (props.groups && props.groups.length) {

        return <Form className="form-fields">{props.groups.length > 1 ?
            (props.groups && props.groups.length > 1 ? renderTabPanes() : renderFormFieldGroups()) : renderFormFields(props.groups[0].formFields)}
        </Form>;
    } else {
        return <Form className="form-fields"></Form>
    }
};

