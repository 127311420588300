import React, { useState } from "react";
import { MainContainer } from "./Layout/MainContainer";
import { AuthContext } from "./Auth/AuthContext";
import { AuthProfile } from "./Auth/AuthProfile";
import { NavigationContext, useNavigationContext } from "./Navigation/NavigationContext";
import { NavigationGroup } from "./Navigation/NavigationUtils";
import { RuntimeContext } from "./Runtime/RuntimeContext";
import { Loader, Dimmer } from "semantic-ui-react";
import { navigationSubject} from "./Navigation/NavigationUtils";
import { BrowserRouter } from "react-router-dom";

export interface EYAMAppProps {
    navigationConfig: NavigationGroup[];
    areaAccessRules: { [key: string]: string[] };
    userProfile: AuthProfile;
    notAllowedRoute: string;
    renderNotAllowedPage: () => JSX.Element;
    notFoundRoute: string;
    renderNotFoundPage: () => JSX.Element;
    homeRoute: string;
    appLogoPath: string;
    renderSceneError?: () => JSX.Element;
    logSceneBoundaryError?: (error: any, errorInfo: any) => void;
    onLogout?: () => any;
    sidebarWidth?: number;
}

export const EYAMApp: React.FunctionComponent<EYAMAppProps> = (props: EYAMAppProps) => {
    const [pageTitle, setPageTitle] = useState("");
    const [language, setLanguage] = useState("");
    const [darkMode, toggleDarkMode] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    let cView = null;
    let cRoles: string[] = [];

    for (let cRole in props.areaAccessRules) {
        cRoles.push(cRole);
    }

    if (props.userProfile == null) {

        cView = <Dimmer active><Loader content="Loading" inline="centered" active></Loader></Dimmer>;
    } else {
        cView = (
            <AuthContext.Provider value={{ userProfile: props.userProfile, areaAccesRules: props.areaAccessRules }}>
                <NavigationContext.Provider value={{ pageTitle: pageTitle , setTitle: (route: string) => {setPageTitle(route)}, navigationSubject:navigationSubject, onBackButtonClick : () => {navigationSubject.notify()}}}>
                    <RuntimeContext.Provider
                        value={{
                            darkMode,
                            toggleDarkMode: () => toggleDarkMode(!darkMode),
                            language,
                            setLanguage: (language: string) => setLanguage(language),
                            isProcessing: isProcessing,
                            setIsProcessing: (isProcessing) => setIsProcessing(isProcessing)
                        }}
                    >
                        <MainContainer
                            navigationConfig={props.navigationConfig}
                            areaAccessRules={props.areaAccessRules}
                            authRoles={cRoles}
                            homeRoute={props.homeRoute}
                            renderNotAllowedPage={props.renderNotAllowedPage}
                            notAllowedRoute={props.notAllowedRoute}
                            renderNotFoundPage={props.renderNotFoundPage}
                            notFoundRoute={props.notFoundRoute}
                            logSceneBoundaryError={props.logSceneBoundaryError}
                            renderSceneError={props.renderSceneError}
                            appLogoPath={props.appLogoPath}
                            onLogout={props.onLogout}
                            sidebarWidth={props.sidebarWidth}
                            isProcessing={isProcessing}
                        />
                    </RuntimeContext.Provider>
                </NavigationContext.Provider>
            </AuthContext.Provider>
        );
    }

    return <BrowserRouter>{cView}</BrowserRouter>;
};
