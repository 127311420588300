import { EYAMApp, LoaderWrapper, NavigationGroup, NotificationService } from "eyam-webui-components";
import { AuthProfile } from "eyam-webui-components/lib/Auth/AuthProfile";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { AuthAreas } from "./Auth/AuthAreas"
import { AuthenticationResult, EndSessionRequest } from "@azure/msal-browser";
import { EYDPCA, API_SCOPE } from "./Auth/AuthConfig";
import {
    InteractionRequiredAuthError,
} from "@azure/msal-browser";

import GlobalSettingsService from "./Services/GlobalSettingsService";
import { BaseGlobalSettings } from "eyam-webui-models";

import UtilityService from "./Services/UtilityService";

export const RoutePaths = {
    HOME: "/web/home",
    ASSETS_VIEW: "/web/assets-view",
    WORKFLOWS_VIEW: "/web/workflows-view",
    WORKFLOW_DETAIL_VIEW: "/web/workflow/:id",
    LOOKUP_TABLES: "/web/lookup-tables",
    APPROVAL_REQUESTS_VIEW: "/web/approval-requests-view",
    APPROVAL_REQUEST_DETAIL_VIEW: "/web/approval-request/:id",
    APPROVAL_REQUEST_USER_DETAIL_VIEW: "/web/approval-request-detail/:id",
    APPROVAL_REQUEST_APPROVER_DETAIL_VIEW: "/web/approval-request-qa/:id",

    COUNT_SESSIONS: "/count-sessions",
    COUNT_DETAIL: "/count-detail/:id",

    APP_LOGS: "/web/app-logs",
    NOT_ALLOWED: "/notallowed",
    NOT_FOUND: "/notfound",
    INITIALIZE_APP: "/web/initialize-app",
};

const LandingPageViewAsync = React.lazy(() => import("./UI/Scenes/Modules/EYDLandingPage"));
const BusinessLocationsViewAsync = React.lazy(() => import("./UI/Scenes/Modules/BusinessLocationView"));
const WorkflowsViewAsync = React.lazy(() => import("./UI/Scenes/Modules/Workflow/WorkflowView"));
const WorkflowDetailViewAsync = React.lazy(() => import("./UI/Scenes/Modules/Workflow/WorkflowDetailView"));
//const ApprovalRequestViewAsync = React.lazy(() => import("./UI/Scenes/Modules/QualityAssurance/Approval/ApprovalRequestView"));
//const ApprovalRequestDetailViewAsync = React.lazy(() => import("./UI/Scenes/Modules/QualityAssurance/Approval/ApprovalRequestDetailView"));

const LocalizationViewAsync = React.lazy(() => import("./UI/Scenes/Modules/Localization/LocalizationView"));
const LocalizationLanguagesViewAsync = React.lazy(() => import("./UI/Scenes/Modules/Localization/LocalizationLanguagesView"));
const LocalizationTypesViewAsync = React.lazy(() => import("./UI/Scenes/Modules/Localization/LocalizationTypesView"));
const GeneralSettingsViewAsync = React.lazy(() => import("./UI/Scenes/GeneralSettingsView"));
const DataTransferViewAsync = React.lazy(() => import("./UI/Scenes/DataTransferView"));
const ExternalIntegrationViewAsync = React.lazy(() => import("./UI/Scenes/Modules/Admin/ExternalIntegration/ExternalIntegrationView"));
const ExternalTriggerDetailViewAsync = React.lazy(() => import("./UI/Scenes/Modules/Admin/ExternalIntegration/ExternalTriggerDetailView"));
const ProcessOrderViewAsync = React.lazy(() => import("./UI/Scenes/Modules/ProcessOrder/ProcessOrderView"));
const ProcessOrderDetailViewAsync = React.lazy(() => import("./UI/Scenes/Modules/ProcessOrder/ProcessOrderDetailView"));
const InitializeAppViewAsync = React.lazy(() => import("./UI/Scenes/InitializeAppView"));


/*const ApprovalRequestManagerViewAsync = React.lazy(
    () => import("./UI/Scenes/Modules/QualityAssurance/Approval/ApprovalRequestManagerView")
);
const ApprovalRequestUserViewAsync = React.lazy(() => import("./UI/Scenes/Modules/QualityAssurance/Approval/ApprovalRequestUserView"));
const ApprovalRequestUserDetailViewAsync = React.lazy(
    () => import("./UI/Scenes/Modules/QualityAssurance/Approval/ApprovalRequestUserDetailView")
);
const ApprovalRequestApproverViewAsync = React.lazy(
    () => import("./UI/Scenes/Modules/QualityAssurance/Approval/ApprovalRequestApproverView")
);
const ApprovalRequestApproverDetailViewAsync = React.lazy(
    () => import("./UI/Scenes/Modules/QualityAssurance/Approval/ApprovalRequestApproverDetailView")
);*/

const ViewGroupsListAsync = React.lazy(() => import("./UI/Scenes/Modules/Admin/ViewsConfig/ViewGroupsList"));
const AppViewsListAsync = React.lazy(() => import("./UI/Scenes/Modules/Admin/ViewsConfig/AppViewsList"));
const FormViewsListAsync = React.lazy(() => import("./UI/Scenes/Modules/Admin/ViewsConfig/FormViewsList"));
const ListViewsListAsync = React.lazy(() => import("./UI/Scenes/Modules/Admin/ViewsConfig/ListViewsList"));
const FieldDependenciesViewAsync = React.lazy(() => import("./UI/Scenes/Modules/Admin/ViewsConfig/FieldDependenciesView"));
const LookupValueDependenciesViewAsync = React.lazy(() => import("./UI/Scenes/Modules/Admin/ViewsConfig/LookupValueDependenciesView"));
const AppViewConfigAsync = React.lazy(() => import("./UI/Scenes/Modules/Admin/ViewsConfig/AppViewConfig"));



const CustomFieldsViewAsync = React.lazy(() => import("./UI/Scenes/Modules/Admin/CustomFields/CustomFieldsView"));
const LookupTablesViewAsync = React.lazy(() => import("./UI/Scenes/Modules/Admin/LookupTable/LookupTablesView"));
//const LookupTableValuesTableView = React.lazy(() => import("./UI/Scenes/Modules/Admin/LookupTable/LookupTableValuesTableView"));
//const LookupTableValuesTreeView = React.lazy(() => import("./UI/Scenes/Modules/Admin/LookupTable/LookupTableValuesTreeView"));
const LookupTableValuesView = React.lazy(() => import("./UI/Scenes/Modules/Admin/LookupTable/LookupTableValuesView"));
const AssetsViewAsync = React.lazy(() => import("./UI/Scenes/Modules/Assets/AssetsView"));
const AssetsAuditHistoryAsync = React.lazy(() => import("./UI/Scenes/Modules/Assets/AssetAuditHistory"));
const InventoryItemsViewAsync = React.lazy(() => import("./UI/Scenes/Modules/InventoryItems/InventoryItemsView"));
const InventoryItemDetailViewAsync = React.lazy(() => import("./UI/Scenes/Modules/InventoryItems/InventoryItemDetailView"));

const ItemMasterDetailViewAsync = React.lazy(() => import("./UI/Scenes/Modules/AssetStock/ItemMasterDetail/ItemMasterDetailView"));
const CountSessionsViewAsync = React.lazy(() => import("./UI/Scenes/Modules/AssetStock/ItemCount/CountSessionsView"));
const CountSessionDetailViewAsync = React.lazy(() => import("./UI/Scenes/Modules/AssetStock/ItemCount/CountSessionDetailView"));
const CountDetailViewAsync = React.lazy(() => import("./UI/Scenes/Modules/AssetStock/ItemCount/CountDetailView"));

const NoMatchPageAsync = React.lazy(() => import("./UI/Shared/NoMatchPage"));
const NotAllowedPageAsync = React.lazy(() => import("./UI/Shared/NotAllowed"));

let navigationConfig: NavigationGroup[] = [];
let bootstrapNavigationConfig: NavigationGroup[] = [];

const initStaticConfig = (t) => {

    navigationConfig = [];

    navigationConfig.push({
        name: t("GENERIC_LABEL-MENU_TITLE"),
        area: AuthAreas.GEN_USER_AREA,
        items: [{ viewTitle: t("GENERIC_LABEL-LANDING_PAGE"), view: <LandingPageViewAsync navigationConfig={navigationConfig} />, path: "/web/home" }]
    });

    navigationConfig.push({
        name: t("GENERIC_LABEL-MY_DASHBOARD"),
        area: AuthAreas.GEN_USER_AREA,
        items: [
            { viewTitle: t("GENERIC_LABEL-ASSETS"), view: <AssetsViewAsync />, path: "/web/assets", iconName: "suitcase" },
            { viewTitle: t("GENERIC_LABEL-ITEM_HISTORY"), view: <AssetsAuditHistoryAsync />, path: "/web/assets/:id", routeOnly: true },
            { viewTitle: t("GENERIC_LABEL-MATERIALS"), view: <InventoryItemsViewAsync />, path: "/web/inventory-items", iconName: "dolly" },
            { viewTitle: t("GENERIC_LABEL-MATERIAL_DETAILS"), view: <InventoryItemDetailViewAsync />, path: "/web/inventory-items/:id", routeOnly: true }
        ]
    });


    navigationConfig.push({
        name: t("GENERIC_LABEL-INVENTORY"),
        area: AuthAreas.GEN_USER_AREA,
        items: [
            { viewTitle: t("GENERIC_LABEL-ITEM_MASTER"), view: <ItemMasterDetailViewAsync />, path: "/web/item-master", iconName: "tags" },
            { viewTitle: t("GENERIC_LABEL-PROCESS_ORDERS"), view: <ProcessOrderViewAsync />, path: "/web/process-order", iconName: "unordered list" },
            { viewTitle: t("GENERIC_LABEL-PROCESS_ORDER_ITEMS"), view: <ProcessOrderDetailViewAsync />, path: "/web/process-order/:id", routeOnly: true },
            { viewTitle: t("GENERIC_LABEL-ITEM_COUNT_SESSIONS"), view: <CountSessionsViewAsync />, path: RoutePaths.COUNT_SESSIONS, iconName: "tags" },
            { viewTitle: t("GENERIC_LABEL-ITEM_COUNTS"), view: <CountSessionDetailViewAsync />, path: `${RoutePaths.COUNT_SESSIONS}/:id`, routeOnly: true },
            { viewTitle: t("GENERIC_LABEL-ITEM_COUNT_DETAILS"), view: <CountDetailViewAsync />, path: RoutePaths.COUNT_DETAIL, routeOnly: true }
        ]
    });


    navigationConfig.push({
        name: t("GENERIC_LABEL-CONFIGURATION"),
        area: AuthAreas.ADMIN_AREA,
        items: [
            { viewTitle: t("GENERIC_LABEL-CUSTOM_FIELDS"), view: <CustomFieldsViewAsync />, path: "/web/custom-fields", iconName: "pencil" },
            { viewTitle: t("GENERIC_LABEL-LOOKUP_TABLES"), view: <LookupTablesViewAsync />, path: "/web/lookup-tables", iconName: "table" },
            { viewTitle: t("GENERIC_LABEL-LOOKUP_TABLE_VALUES"), view: <LookupTableValuesView />, path: "/web/lookup-tables/:id", routeOnly: true, },
            { viewTitle: t("GENERIC_LABEL-APP_VIEWS"), view: <ViewGroupsListAsync />, path: "/web/app-view-groups", iconName: "file alternate" },
            { viewTitle: t("GENERIC_LABEL-APP_VIEWS"), view: <AppViewsListAsync />, path: "/web/app-views/:id", routeOnly: true, },
            { viewTitle: t("GENERIC_LABEL-FORM_VIEWS"), view: <FormViewsListAsync />, path: "/web/form-views/:id", routeOnly: true },
            { viewTitle: t("GENERIC_LABEL-LIST_VIEWS"), view: <ListViewsListAsync />, path: "/web/list-views/:id", routeOnly: true },
            { viewTitle: t("GENERIC_LABEL-FORM_VIEW_CONFIG"), view: <AppViewConfigAsync />, path: "/web/app-view-config/:id", routeOnly: true },
            { viewTitle: t("GENERIC_LABEL-FIELD_DEPENDENCIES"), view: <FieldDependenciesViewAsync />, path: "/web/field-dependencies", iconName: "sitemap" },
            { viewTitle: t("GENERIC_LABEL-LOOKUP_VALUE_DEPENDENCIES"), view: <LookupValueDependenciesViewAsync />, path: "/web/field-dependencies/:id", routeOnly: true },
            { viewTitle: t("GENERIC_LABEL-LOCATIONS"), view: <BusinessLocationsViewAsync />, path: "/web/locations", iconName: "location arrow" },
            { viewTitle: t("GENERIC_LABEL-EXTERNAL_INTEGRATION"), view: <ExternalIntegrationViewAsync />, path: "/web/external-integration", iconName: "external share" },
            { viewTitle: t("GENERIC_LABEL-EXTERNAL_TRIGGER_LOGS"), view: <ExternalTriggerDetailViewAsync />, path: "/web/external-integration/:id", routeOnly: true },
            { viewTitle: t("GENERIC_LABEL-DATA_TRANSFER"), view: <DataTransferViewAsync />, path: "/web/data-transfer", iconName: "file excel" },
            { viewTitle: t("GENERIC_LABEL-SETTINGS"), view: <GeneralSettingsViewAsync />, path: "/web/general-settings", iconName: "cogs" },
            { viewTitle: t("GENERIC_LABEL-WORKFLOWS"), view: <WorkflowsViewAsync />, path: RoutePaths.WORKFLOWS_VIEW, iconName: "clipboard check", routeOnly: true },
            { viewTitle: t("GENERIC_LABEL-WORKFLOW"), view: <WorkflowDetailViewAsync />, path: RoutePaths.WORKFLOW_DETAIL_VIEW, routeOnly: true },
            { viewTitle: t("GENERIC_LABEL-WORKFLOWS"), view: <WorkflowsViewAsync />, path: RoutePaths.WORKFLOWS_VIEW, iconName: "clipboard check" },
            { viewTitle: t("GENERIC_LABEL-WORKFLOW"), view: <WorkflowDetailViewAsync />, path: RoutePaths.WORKFLOW_DETAIL_VIEW, routeOnly: true }
        ]

    });

     //navigationConfig.push({
     //    name: t("GENERIC_LABEL-APPROVAL_WORKFLOW"),
     //    area: AuthAreas.ADMIN_AREA,
     //    items: [
     //        //{ viewTitle: t("GENERIC_LABEL-APPROVAL_REQUESTS"), view: <ApprovalRequestViewAsync />, path: RoutePaths.APPROVAL_REQUESTS_VIEW, iconName: "clipboard outline" },
     //        //{
     //        //    viewTitle: t("GENERIC_LABEL-APPROVAL_REQUEST"),
     //        //    view: <ApprovalRequestDetailViewAsync />,
     //        //    path: RoutePaths.APPROVAL_REQUEST_DETAIL_VIEW,
     //        //    routeOnly: true
     //        //},
     //        //{ viewTitle: t("GENERIC_LABEL-MY_APPROVAL_REQUESTS"), view: <ApprovalRequestUserViewAsync />, path: "/web/approval-requests-user", iconName: "clipboard" },
     //        //{
     //        //    viewTitle: t("GENERIC_LABEL-MY_APPROVAL_REQUEST"),
     //        //    view: <ApprovalRequestUserDetailViewAsync />,
     //        //    path: RoutePaths.APPROVAL_REQUEST_USER_DETAIL_VIEW,
     //        //    routeOnly: true
     //        //},
     //        //{ viewTitle: t("GENERIC_LABEL-ASSIGNED_APPROVALS"), view: <ApprovalRequestApproverViewAsync />, path: "/web/approval-request-qa", iconName: "clipboard list" },
     //        //{
     //        //    viewTitle: t("GENERIC_LABEL-MY_APPROVAL_REQUEST"),
     //        //    view: <ApprovalRequestApproverDetailViewAsync />,
     //        //    path: RoutePaths.APPROVAL_REQUEST_APPROVER_DETAIL_VIEW,
     //        //    routeOnly: true
     //        //},
     //        //{ viewTitle: t("GENERIC_LABEL-APPROVAL_ASSIGNMENT"), view: <ApprovalRequestManagerViewAsync />, path: "/web/approval-request-manager", iconName: "paste" },
             
     //    ]
     //});



    navigationConfig.push({
        name: t("GENERIC_LABEL-LOCALIZATION"),
        area: AuthAreas.ADMIN_AREA,
        items: [
            { viewTitle: t("GENERIC_LABEL-LANGUAGES"), view: <LocalizationLanguagesViewAsync />, path: "/web/languages", iconName: "language" },
            { viewTitle: t("GENERIC_LABEL-TYPES"), view: <LocalizationTypesViewAsync />, path: "/web/localization-types", iconName: "wrench" },
            { viewTitle: t("GENERIC_LABEL-VALUES"), view: <LocalizationViewAsync />, path: "/web/localization", iconName: "key" }
        ]
    });

    bootstrapNavigationConfig = [];

    bootstrapNavigationConfig.push({
        name: "App Initialization",
        area: AuthAreas.ADMIN_AREA,
        items: [
            { viewTitle: "Initialize", view: <InitializeAppViewAsync />, path: "/web/initialize-app" }
        ]
    });
}



export const EYDApp: React.FunctionComponent<{}> = () => {
    const [cProfile, setAuthData] = useState<AuthProfile>(null);
    const { t } = useTranslation();
    const [bootstrapStatus, setBootstrapStatus] = useState(null);
    const [authRules, setAuthRules] = useState<{ [key: string]: string[] }>({});
    const [isLoading, setIsLoading] = useState(true);
    const [utilityError, setUtilityError] = useState(false);

    useEffect(() => {

        initStaticConfig(t);
        loadServerConfig().finally(() => {
            setIsLoading(false);
        });

    },[]);

    const loadServerConfig = async () => {


        let profile: AuthProfile;

        try {

            let authResult = await acquireMsalAccount();

            const { account, accessToken } = authResult;
            const claims = JSON.parse(window.atob(accessToken.split(".")[1]));

            profile = {
                roles: claims.roles,
                displayName: account.name,
                userId: account.homeAccountId
            };

            setAuthData(profile);
        } catch (e) {
            console.error("Failed to load auth profile");
            setUtilityError(true);
        }

        if (profile?.roles?.length > 0) {

            try {
                let bootStrappedCfg = await GlobalSettingsService.getGlobalSettingById(BaseGlobalSettings.Bootstrapped);

                if (bootStrappedCfg.settingId === "00000000-0000-0000-0000-000000000000") {
                    setBootstrapStatus(false);
                } else {
                    if (bootStrappedCfg.settingValue) {
                        setBootstrapStatus(bootStrappedCfg.settingValue.valueBoolean);
                    } else {
                        setBootstrapStatus(false);
                    }
                }
            } catch (e) {
                NotificationService.showError(e + "", "OK", e);
                console.error("Failed to load app bootstrap status");
                if (profile?.userId) {
                    setBootstrapStatus(true);
                } else {
                    setBootstrapStatus(false);
                }
            }
        } else {

            setBootstrapStatus(false);
        }

        try {
            let auth = await UtilityService.getAuthRules();

            setAuthRules(auth);

        } catch (e) {
            setUtilityError(true);
            console.error("Failed to load app auth rules");
            if (profile?.userId) {
                setBootstrapStatus(true);
            } else {
                setBootstrapStatus(false);
            }
        }
    }


    const acquireMsalAccount = async () => {
        const activeAccount = EYDPCA.getActiveAccount();
        const accounts = EYDPCA.getAllAccounts();

        if (!activeAccount && accounts.length === 0) {
            throw "User not logged in";
        }

        const request = {
            scopes: [API_SCOPE],
            account: activeAccount || accounts[0]
        };

        let authRes: AuthenticationResult = null;

        try {

            authRes = await EYDPCA.acquireTokenSilent(request);

        } catch (err) {
            if (err instanceof InteractionRequiredAuthError) {

                try {
                    await EYDPCA.acquireTokenRedirect(request);
                } catch (err2) {
                    console.log(err2);
                }
            } else {
                throw err;
            }
        }

        return authRes;
    };

    const logoutHandler = async () => {
        const allAccounts = EYDPCA.getAllAccounts();
        const account = EYDPCA.getActiveAccount() || allAccounts[0];
        const logoutRequest: EndSessionRequest = {
            account,
            postLogoutRedirectUri: window.location.origin
        }
        setIsLoading(true);
        try {
            await UtilityService.cancelAccessToken();
            await EYDPCA.logoutRedirect(logoutRequest);
        } catch (error) {
            NotificationService.showError(error + "", t("GENERIC_BUTTON_OK"), error)
        }
        finally {
            setIsLoading(false);
        }
    }

    if (bootstrapStatus == null) {
        return (
            <LoaderWrapper inverted active={isLoading}>
            </LoaderWrapper>
        );

    } else if (utilityError) {
        return (
            <>Something went wrong.</>
        );
    }
    else if (cProfile?.roles?.length > 0) {

        if (bootstrapStatus) {
            return (
                <LoaderWrapper inverted active={isLoading}>
                    {!isLoading && 
                        <EYAMApp
                            navigationConfig={navigationConfig}
                            areaAccessRules={authRules}
                            userProfile={cProfile}
                            notAllowedRoute={RoutePaths.NOT_ALLOWED}
                            renderNotAllowedPage={() => <NotAllowedPageAsync />}
                            notFoundRoute={RoutePaths.NOT_FOUND}
                            renderNotFoundPage={() => <NoMatchPageAsync />}
                            homeRoute={RoutePaths.HOME}
                            appLogoPath={"/images/logo_simple_xs.png"}
                            renderSceneError={() => { return <>{t("GENERIC_ERROR_ALERT-WENT_WRONG")}</> }}
                            onLogout={() => logoutHandler()}
                            sidebarWidth={250}
                        />
                    }
                </LoaderWrapper>
            );

        }
    }
    return (
        <LoaderWrapper inverted active={isLoading}>
            {!isLoading && 
                <EYAMApp
                    navigationConfig={bootstrapNavigationConfig}
                    areaAccessRules={authRules}
                    userProfile={cProfile}
                    notAllowedRoute={RoutePaths.NOT_ALLOWED}
                    renderNotAllowedPage={() => <NotAllowedPageAsync />}
                    notFoundRoute={RoutePaths.NOT_FOUND}
                    renderNotFoundPage={() => <NoMatchPageAsync />}
                    homeRoute={RoutePaths.INITIALIZE_APP}
                    appLogoPath={"/images/logo_simple_xs.png"}
                    renderSceneError={() => { return <>{t("GENERIC_ERROR_ALERT-WENT_WRONG")}</> }}
                    onLogout={() => logoutHandler()}
                    sidebarWidth={250}
                />
            }
        </LoaderWrapper>
    );


};
