import React from "react";
import { Input } from "semantic-ui-react";

interface DecimalInputProps {
    [key: string]: any;
    onChange: (value: number | string) => any;
}

const getDecimal = (value: any) => {
    let floatNumber = parseFloat(value);
    return Number.isNaN(floatNumber) ? 0 : floatNumber;
};

const DecimalInput: React.FunctionComponent<DecimalInputProps> = (props) => {
    const value = props.defaultValue;
    const onChange = (_, { value }) => {
        if (value.substr(value.length - 1) === '.') {
            props.onChange(value);
        } else {
            const newValue = getDecimal(value);
            props.onChange(newValue);
        }       
    };

    const inputProps = { ...props, onChange, value, defaultValue: undefined };
    return <Input {...inputProps}></Input>;
};

export { DecimalInput };
