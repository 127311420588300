export var DynamicEntityFieldType;
(function (DynamicEntityFieldType) {
    DynamicEntityFieldType[DynamicEntityFieldType["String"] = 0] = "String";
    DynamicEntityFieldType[DynamicEntityFieldType["Integer"] = 1] = "Integer";
    DynamicEntityFieldType[DynamicEntityFieldType["Float"] = 2] = "Float";
    DynamicEntityFieldType[DynamicEntityFieldType["Long"] = 3] = "Long";
    DynamicEntityFieldType[DynamicEntityFieldType["Boolean"] = 4] = "Boolean";
    DynamicEntityFieldType[DynamicEntityFieldType["Date"] = 5] = "Date";
    DynamicEntityFieldType[DynamicEntityFieldType["Lookup"] = 6] = "Lookup";
    DynamicEntityFieldType[DynamicEntityFieldType["LongString"] = 7] = "LongString";
    DynamicEntityFieldType[DynamicEntityFieldType["Array"] = 8] = "Array";
})(DynamicEntityFieldType || (DynamicEntityFieldType = {}));
