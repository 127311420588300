import React from "react";

interface ErrorBoundaryProps {
    renderError: () => JSX.Element;
    logError: (error: any, errorInfo: any) => void;
    children?: React.ReactNode;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        this.props.logError ? this.props.logError(error, errorInfo) : console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return this.props.renderError ? this.props.renderError() : "ERROR";
        }
        return this.props.children;
    }
}