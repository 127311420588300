import React, { useContext } from "react";
import { AuthProfile } from "./AuthProfile";
export const AuthContext = React.createContext<{ userProfile: AuthProfile, areaAccesRules: { [key: string]: string[] } }>({
    userProfile: {
        roles: [],
        displayName: "",
        userId: ""
    },
    areaAccesRules: {}
});

export const useAuthContext = () => useContext(AuthContext);
