const deviceMediaQuery = "(max-device-width: 1024px)";
import { useEffect, useState } from "react";

const copyAndSort = <T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] => {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

const debounce = (func: (...args: any[]) => any, timeout: number = 500) => {
    let timer: any;
    return (...args: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(...args);
        }, timeout);
    };
}

const useDebounce = (value, delay) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
}


const trimString = (value: string, maxLength): string => {
    let trimmedVal = value.trim();
    if (trimmedVal.length > maxLength) {
        return trimmedVal.substring(0, maxLength - 3) + "...";
    }
    return trimmedVal;
}

const getItemContent = <T,>(item: T,
    displayConfig: [ keyof T, (data) => JSX.Element,  ((data) => string)[],  boolean]) => {

    let key: string = displayConfig[0] as string;
    let customRender = displayConfig[1];
    let formaters = displayConfig[2];
    let customRenderUseFormatData = displayConfig[3];
    let formatedData = item[key] as unknown;

    if (formaters) {
        formaters.map(formatter => {
            formatedData = formatter(formatedData);
        })
    }

    if (customRender) {
        let itemDescription = customRenderUseFormatData ?
            customRender({ ...item, [key]: formatedData })
            : customRender(item);
        return itemDescription;
    }
    else {
        return formatedData;
    }
}


const isNullOrUndefined = (value: any)=> {
    return value === null || value === undefined;
}

export { copyAndSort, debounce, trimString, deviceMediaQuery, getItemContent, useDebounce, isNullOrUndefined };
