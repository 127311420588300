import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { Dimmer, Loader } from "semantic-ui-react";
import { EYDApp } from "./EYDApp";

export const EYDAppAuthWrapper: React.FunctionComponent<{}> = () => {

    //const { t } = useTranslation();

    const authRequest = {
        scopes: ["openid", "profile"]
    };

    const renderAuthLoader = () => {
        return <Dimmer>
            <Loader size='big'>"Loading..."</Loader>
        </Dimmer>
    }

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={() => <div>error</div>}
            loadingComponent={() => renderAuthLoader()}>
            <EYDApp />
        </MsalAuthenticationTemplate>
    );
};
