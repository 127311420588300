export class PhotoBase {
    constructor() {
        this.photoID = "";
        this.ownerID = "";
        this.data = null; //string
        this.description = "";
        this.isRemoved = false;
        this.url = "";
        this.runtimeUID = "00000000-0000-0000-0000-000000000000";
    }
}
