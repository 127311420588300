import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { NotificationService } from "eyam-webui-components";
import { EYDPCA } from "../Auth/AuthConfig";



i18n.use(Backend)
    .use(initReactI18next)
    .init({
        lng: "en",
        fallbackLng: "en", // use en if detected lng is not available
        
        backend: {
            loadPath: "/api/Localization/GetLocalizationValuesJSON?language={{lng}}",
            request: async(options, url, payload, callback) => {
                try {
                    let resp;
                    resp = await fetch(url);
                    
                    if (!resp.ok) {
                        const accounts = EYDPCA.getAllAccounts();
                        if (accounts.length !== 0) {
                            NotificationService.showError("Failed to load resources." + "", "OK");
                        }
            
                        return callback('failed to fetch...', {status: 400, data: ""});
                    }
                    const data = await resp.json();
                    callback(null,{
                        status: 200,
                        data: JSON.stringify(data),
                    });
                } catch (e) {
                    callback(e, {status: 400, data: ""});
                }

            }
            
        },
        interpolation: {
            escapeValue: false // react already escapes
        },
        keySeparator: "-" // we do not use keys in form messages.welcome
    },(e) => {
      
      });
    
export default i18n;
