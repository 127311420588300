type SearchCriteria =
    "strict" |
    "contain" |
    "beginWith" |
    "regex" |
    ((text: string, searchKeyWord: string) => boolean)

function searchArray<T,>(items: T[], searchKeyWord: string, displayConfig: [keyof T, (data) => JSX.Element, ((data) => string)[], boolean, SearchCriteria][],
    isCaseSensitive: boolean = false) {
    let filteredItems: T[] = [];

    for (let item of items) {

        if (meetCondition((item as unknown as T), searchKeyWord, displayConfig, isCaseSensitive)) {
            filteredItems.push(item as unknown as T);
        }
    }

    return filteredItems;  
}

function meetCondition<T,>(item: T, searchKeyWord: string,
    displayConfig: [key: keyof T, customRender: (data) => JSX.Element, formaters: ((data) => string)[],useFormatData: boolean, searchCrit: SearchCriteria][],
    isCaseSensitive: boolean = false) {
    
    if (!isCaseSensitive) {
        searchKeyWord = searchKeyWord.toLocaleLowerCase();
    }

    for (let displayProperty of displayConfig) {
        let key: string = displayProperty[0] as string;
        let customRender = displayProperty[1];
        let formater = displayProperty[2];
        let searchCrit = displayProperty[4];
        let customRenderUseFormatData = displayProperty[3];

        let isValid: boolean;
        let text: string;
        let displayValue = item[key];
        
        if (formater != null) {
            for (let formatMethod of formater) {
                displayValue = formatMethod(displayValue);
            }
        }

        if (customRender != null) {
            let renderVal;
            if (customRenderUseFormatData && formater != null) {
                renderVal = customRender({ ...item, [key]: displayValue})
            }
            else {
                renderVal= customRender(item);
            }
             
            if (renderVal?.props != null && typeof renderVal.props.children == "string") {
                displayValue = renderVal.props.children as string;
            }
        }

        text = (displayValue + '')

        if (!isCaseSensitive) {
            text = text.toLocaleLowerCase();
        }

        if (typeof searchCrit == "string") {
            switch (searchCrit) {
                case "contain":
                    isValid = containSearch(text, searchKeyWord);
                    break;
                case "strict":
                    isValid = strictSearch(text, searchKeyWord);
                    break;
                case "beginWith":
                    isValid = beginWithSearch(text, searchKeyWord);
                    break;
                case "regex":
                    isValid = regexSearch(text, searchKeyWord);
                    break;
                default:
                    break;
            }
        }
        else if (typeof searchCrit == "function"){
            let customSearchCriteria = searchCrit as unknown as ((text: string, searchKeyWord: string) => boolean);

            isValid = customSearchCriteria(text, searchKeyWord)
        }

        if (isValid) {
            return true;
        }             
    }

    return false;
}

function strictSearch(text: string, searchKeyWord: string) {
    return text == searchKeyWord;  
}

function containSearch(text: string, searchKeyWord: string) {
    return text.indexOf(searchKeyWord) > -1 ? true : false;
}

function beginWithSearch(text: string, searchKeyWord: string) {
    return text.indexOf(searchKeyWord) == 0 ? true : false;
}

function regexSearch(text: string, searchKeyWord: string) {
    let modifier = "gi";
    let searchPattern = '.*' + searchKeyWord.split('').join(".*") + '.*';
    const searchRegex = new RegExp(searchPattern, modifier);

    return searchRegex.test(text);
}

export { searchArray, SearchCriteria };