import React, { useEffect } from "react";
import { useState } from "react";
import { Table } from "semantic-ui-react";
import { ItemDisplayConfig } from "./ItemDisplayConfig";
import { ActionProps } from "./ActionProps";

interface CustomTableHeaderProps<T> {
    properties: ItemDisplayConfig<T>[];
    onSortingColumnChanged: (newState: keyof T) => void;
    onSortingOrderChanged(newState: string): void;
    itemActions: ActionProps[];
}

const CustomTableHead = <T,>(props: CustomTableHeaderProps<T>) => {
    const [state, setState] = useState({
        column: null,
        direction: null
    });

    const { column, direction } = state;

    const onHeaderClickHandler = (state, actionColumn, isSortable) => {
        if (isSortable) {
            if (state.column === actionColumn) {
                setState({ column: actionColumn, direction: state.direction === "ascending" ? "descending" : "ascending" });
            } else {
                setState({ column: actionColumn, direction: "ascending" });
            }
        }
    };

    useEffect(() => {
        if (props.onSortingColumnChanged) {
            props.onSortingColumnChanged(state.column);
        }

        if (props.onSortingOrderChanged) {
            props.onSortingOrderChanged(state.direction);
        }
    }, [state]);

    return (
        <Table.Header>
            <Table.Row>
                {props.properties
                    .filter((property) => property.displayPlaces?.indexOf("table") > -1)
                    .map((property, index) => (
                        <Table.HeaderCell
                            key={index}
                            sorted={property.sortable && column === property.key ? direction : null}
                            onClick={() => {
                                onHeaderClickHandler(state, property.key, property.sortable);
                            }}
                        >
                            {property.label}
                        </Table.HeaderCell>
                    ))}
                {props.itemActions && <Table.HeaderCell key={"itemActions"} width={1}/>}
            </Table.Row>
        </Table.Header>
    );
};

export { CustomTableHead, CustomTableHeaderProps };