import React from "react";

interface AuthSwitchProps {
    renderAllowed: () => JSX.Element;
    renderDenied: () => JSX.Element;
    roles: string[];
    areaAccessRules: { [key: string]: string[] };
    area: string;
}


export const isAllowed = (roles: string[], accessRules: { [key: string]: string[] },area:string) => {
    try {

        let cRoles = roles ? roles : [];

        for (let cRole of cRoles) {
            const permissions = accessRules[cRole];
            if (!permissions) {
                continue;
            }
            if (permissions && permissions.includes(area)) {
                // static rule not provided for action
                return true;
            }
        }

        return false;
    } catch {
        console.log("AuthSwitchFailed");
        return false;
    }
};


export const AuthSwitch: React.FunctionComponent<AuthSwitchProps> = (props) => {
    return (<>{isAllowed(props.roles, props.areaAccessRules, props.area) ? props?.renderAllowed() : props?.renderDenied()}</>);
};

AuthSwitch.defaultProps = {
    renderAllowed: () => null,
    renderDenied: () => null
};
