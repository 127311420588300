export class TriggerEventLog {
    constructor() {
        this.logId = "";
        this.externalTriggerID = "";
        this.payload = "";
        this.lastModifiedDate = null;
        this.isSuccess = false;
        this.error = "";
        this.headers = [];
    }
}
