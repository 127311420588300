export class IntrinsicAssetCustomFieldID {
}
IntrinsicAssetCustomFieldID.AssetID = "b1de9441-4038-4ec4-8ce8-e697e15f4129";
IntrinsicAssetCustomFieldID.SerialNumber = "c39f26ef-6b5f-427e-976a-6fb0d44c1180";
IntrinsicAssetCustomFieldID.SKU = "884b5b7c-9832-40a3-995e-71678c5dc945";
IntrinsicAssetCustomFieldID.Latitude = "d368e2bb-3ba3-4a17-ac4b-643317cddaf9";
IntrinsicAssetCustomFieldID.Longitude = "868a23e6-ae31-4e01-bcdb-2a53a02c90ef";
IntrinsicAssetCustomFieldID.LocationID = "36d2e2ca-2309-403f-accb-22c978462eb4";
IntrinsicAssetCustomFieldID.LastModifiedBy = "85dab56b-a9fb-47f6-bd0b-0b24c8c69fdb";
IntrinsicAssetCustomFieldID.LastModifiedDate = "346d88fb-4a5b-4fdd-b96f-574ea59d5ba3";
