import { Configuration, PublicClientApplication } from "@azure/msal-browser";

// MSAL configuration
const configuration: Configuration = {
    auth: {
        clientId: window.spaSettings.clientID,
        authority: window.spaSettings.authority,
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        navigateToLoginRequestUrl: false
    }
};

export const EYDPCA = new PublicClientApplication(configuration);

export const API_SCOPE: string = window.spaSettings.scope;


