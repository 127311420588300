import React from "react";
import { useEffect, useState } from "react";
import { Icon, Input, Segment } from "semantic-ui-react";
import { debounce } from "../../Utility/utility";
import { DynamicItemsViewerProps } from "./DynamicItemsViewerProps";
import { ItemsPerPageDropdown } from "./ItemsPerPageDropdown";
import { ItemDisplayConfig } from "./ItemDisplayConfig";
import { searchArray } from "../../Utility/SearchUtil";
import { sortArray } from "../../Utility/SortUtil";
import { SortingPropertiesDropdown } from "./SortingPropertiesDropdown";
import { ItemCard } from "./ItemCard";


export const ItemsCardView = <T,>(props: DynamicItemsViewerProps<T>) => {
    const { properties, items } = props;
    const [activePage, setActivePage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [filteredItems, setFilteredItems] = useState<T[]>(items as T[]);
    const [searchKeyWord, setSearchKeyWord] = useState("");
    const [arrowDirection, setArrowDirection] = useState<any>("arrow down");
    const [sortingProperty, setSortingProperty] = useState<keyof T>();
    const [sortingDirection, setSortingDirection] = useState("");

    useEffect(() => {
        if (props.onSortingDirectionChanged) {
            props.onSortingDirectionChanged(sortingDirection);
        }

        if (props.onSortingPropertyChanged) {
            props.onSortingPropertyChanged(sortingProperty);
        }
    }, [sortingProperty, sortingDirection]);

    useEffect(() => {
        let fItems = filterItems(items, searchKeyWord, props.properties);
        let sortedItems = sortItems(fItems);
        setFilteredItems(sortedItems);
    }, [items, searchKeyWord, sortingProperty, sortingDirection]);

    const filterItems = (items: T[], query: string, itemDisplayConfigs: ItemDisplayConfig<T>[]) => {
        let fItems: T[];

        if (query && query != "") {
            let filterDisplayConfigs = itemDisplayConfigs
                .filter((col) => col.searchable)
                .map((col) => {
                    return [
                        col.key,
                        col.customRender,
                        col.formaters,
                        col.customRenderUseFormatData,
                        col.searchCriteria != null ? col.searchCriteria : "contain"
                    ];
                });

            fItems = searchArray(items, query, filterDisplayConfigs as [], false);
        } else {
            fItems = items;
        }

        return fItems;
    };

    const sortItems = (itms: T[]) => {
        let sortableColumns = props.properties.filter((col) => col.sortable).map((col) => col.key);

        if (sortingProperty == null || sortingProperty == "" || sortableColumns.indexOf(sortingProperty as keyof T) < 0) {
            return itms;
        }
        let displayConfig = props.properties.find((col) => col.key == sortingProperty);

        return sortArray(
            itms,
            sortingProperty,
            displayConfig.customRender,
            displayConfig.formaters,
            displayConfig.customRenderUseFormatData,
            sortingDirection == "ascending"
        );
    };

    const getNumberOfPages = () => {
        return props.pagination ? Math.ceil(filteredItems.length / itemsPerPage) : 1;
    };

    const handleClick = (item) => {
        if (props.onItemsClick) {
            props.onItemsClick(item);
        }
    };

    return (
        <>
            <Segment attached={props.attached}>
                <div className="items-view-row items-view-flex-row">
                    <div className="items-view-flex-item">
                        <SortingPropertiesDropdown
                            setSortingOrder={(newState: any) => setArrowDirection(newState)}
                            setSortingProperty={(newState: keyof T) => setSortingProperty(newState)}
                            properties={properties}
                        />
                    </div>
                    <div className="items-view-flex-item">
                        <Icon
                            name={arrowDirection}
                            size="big"
                            onClick={() => {
                                if (arrowDirection === "arrow down") {
                                    setArrowDirection("arrow up");
                                    setSortingDirection("ascending");
                                } else {
                                    setArrowDirection("arrow down");
                                    setSortingDirection("descending");
                                }
                            }}
                            onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                            }}
                        />
                    </div>
                </div>
                {(properties.some((pr) => pr.searchable) || props.pagination || props.customTools) && (
                    <div className="items-view-row items-view-flex-row">
                        <div className="items-view-flex-item">
                            <ItemsPerPageDropdown
                                noOfItemsToShowArray={props.noOfItemsToShowArray}
                                setItemsPerPage={(newState: number) => {
                                    setItemsPerPage(newState);
                                    if (props.onNumberOfItemsShownChanged) {
                                        props.onNumberOfItemsShownChanged(newState);
                                    }
                                    if (props.onActivePageChanged) {
                                        props.onActivePageChanged(1);
                                    }
                                    setActivePage(1);
                                }}
                            />
                        </div>
                        {props.pagination && getNumberOfPages() >= 2 && (
                            <div className="items-view-flex-item">
                                <Icon
                                    size="large"
                                    name="angle left"
                                    onClick={() => {
                                        if (activePage > 1) {
                                            if (props.onActivePageChanged) {
                                                props.onActivePageChanged(activePage - 1);
                                            }
                                            setActivePage(activePage - 1);
                                        }
                                    }}
                                    onMouseOver={(e) => {
                                        e.target.style.cursor = "pointer";
                                    }}
                                />
                                {activePage} / {props.pagination ? Math.ceil(filteredItems.length / itemsPerPage) : 1}
                                <Icon
                                    size="large"
                                    name="angle right"
                                    onClick={() => {
                                        if (activePage < getNumberOfPages()) {
                                            if (props.onActivePageChanged) {
                                                props.onActivePageChanged(activePage + 1);
                                            }
                                            setActivePage(activePage + 1);
                                        }
                                    }}
                                    onMouseOver={(e) => {
                                        e.target.style.cursor = "pointer";
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
                <div className="items-view-row items-view-flex-item">
                    <Input
                        fluid
                        icon="search"
                        placeholder="Search..."
                        onChange={debounce((event, data) => {
                            setSearchKeyWord(data.value);
                            if (props.onSearchKeyWordChanged) {
                                props.onSearchKeyWordChanged(data.value);
                            }
                            if (props.onActivePageChanged) {
                                props.onActivePageChanged(1);
                            }
                            setActivePage(1);
                        })}
                    />
                </div>
                <div className="items-view-row items-view-flex-item">
                    {props.customTools &&
                        props.customTools.map((el) => {
                            return <div className="items-view-flex-item">{el}</div>;
                        })}
                </div>
            </Segment>
            {filteredItems.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage).map((item, index) => {
                return <ItemCard item={item} key={index} properties={properties} itemActions={props.itemActions} />;
            })}
        </>
    );
};
