
function sortArray<T,>(items: T[], key, customRender: (data) => JSX.Element, formaters: ((data) => string)[],
    customRenderUseFormatData: boolean, isAscending: boolean) {
    let sortedItems = [];

    if (isAscending) {
        sortedItems = [...items].sort((a: T, b: T) => {
            let valueA: any = getSortValue(a, key, customRender, formaters, customRenderUseFormatData);
            let valueB: any = getSortValue(b, key, customRender, formaters, customRenderUseFormatData);
            if (typeof valueA == "number") {
                return (valueA as unknown as number) - (valueB as unknown as number)
            }
            else if (typeof valueA == "string") {
                return (valueA as unknown as string).localeCompare(valueB as unknown as string);
            }
            else {
                return (valueA - valueB);
            }
        })
    }
    else {
        sortedItems = [...items].sort((a: T, b: T) => {
            let valueA: any = getSortValue(a, key, customRender, formaters, customRenderUseFormatData);
            let valueB: any = getSortValue(b, key, customRender, formaters, customRenderUseFormatData);
            if (typeof valueA == "number") {
                return (valueB as unknown as number) - (valueA as unknown as number)
            }
            else if (typeof valueB == "string") {
                return (valueB as unknown as string).localeCompare(valueA as unknown as string);
            }
            else {
                return valueB - valueA;
            }
        })
    }

    return sortedItems;
}


function getSortValue<T,>(item: T, key, customRender: (data) => JSX.Element, formaters: ((data) => string)[], customRenderUseFormatData: boolean  ) {
    let displayValue = item[key];

    if (formaters != null) {
        for (let formatMethod of formaters) {
            displayValue = formatMethod(displayValue);
        }
    }

    if (customRender != null) {
        let renderVal;
        if (customRenderUseFormatData && formaters != null) {
            renderVal = customRender({ ...item, [key]: displayValue })
        }
        else {
            renderVal = customRender(item);
        }

        if (renderVal?.props != null &&
            (typeof renderVal.props.children == "string" || typeof renderVal.props.children == "number")) {
            displayValue = renderVal.props.children;
        }
    }

    return displayValue;
}

export { sortArray }