import React, { Fragment } from "react";
import { Icon, Menu, Sidebar, Header, Image } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { deviceMediaQuery, trimString } from "../Utility/utility";
import { useAuthContext } from "../Auth/AuthContext";
import { isAllowed } from "../Auth/AuthSwitch";
import { NavigationGroup } from "../Navigation/NavigationUtils";

interface NavigationMenuProps {
    visible: boolean;
    onHide?: () => any;
    onLogout?: () => any;
    sidebarWidth?: number;
    navigationConfig: NavigationGroup[];
    authRoles: string[];
    authAccessRules: { [key: string]: string[] };
}

const NavigationMenu: React.FunctionComponent<NavigationMenuProps> = (props: NavigationMenuProps) => {
    const isTabletOrMobile = useMediaQuery({ query: deviceMediaQuery });
    const authContext = useAuthContext();


    let renderContent = () => {
        let content: JSX.Element;
        if (isTabletOrMobile) {
            content = (
                <Sidebar as={Menu} style={ { width: props.sidebarWidth } } direction="right" animation="overlay" inverted onHide={props.onHide} vertical visible={props.visible}>
                    {renderMenuItems()}
                </Sidebar>
            );
        } else {
            content = (
                <Menu inverted vertical fixed="left">
                    {renderMenuItems()}
                </Menu>
            );
        }
        return content;
    };

    let renderMenuItems = () => {
        return (
            <Fragment>
                <Menu.Item>
                    {isTabletOrMobile ? (
                        <div className="user-info">
                            <Header size="small">
                                <Icon fitted name="user circle" size="big" inverted />
                                <Header.Content className="asset-manager-header__inverted">
                                    {authContext.userProfile.displayName}
                                </Header.Content>
                            </Header>
                        </div>
                    ) : (
                        <Image className="asset-manager-header__ey-logo" src="/images/logo_simple_xs.png" />
                    )}
                </Menu.Item>
                {props.navigationConfig.map((mg, index) => {
                    let key = "menu-group2-" + index;

                    if (!isAllowed(authContext?.userProfile?.roles ? authContext?.userProfile?.roles :[] , props.authAccessRules, mg.area)) {
                        return;
                    }

                    return (
                        <Menu.Item key={key} exact="true">
                            <Menu.Header>{mg.name}</Menu.Header>
                            <Menu.Menu>
                                {mg.items.map((mgItem, index) => {
                                    if (mgItem.routeOnly) {
                                        return;
                                    }
                                    let viewTitle = trimString(mgItem.viewTitle, 20);
                                    return (
                                        <NavLink to={mgItem.path} key={key + "__menu-item-" + index} className="item" onClick={props.onHide}>
                                            {viewTitle}
                                        </NavLink>
                                    );
                                })}
                            </Menu.Menu>
                        </Menu.Item>
                    );
                })}
                <Menu.Item onClick={props.onLogout}>Log out</Menu.Item>
            </Fragment>
        );
    };

    return <div className="navigation-menu">{renderContent()}</div>;
};

export default NavigationMenu;
