import React, { useEffect, useState } from "react";
import { ControlledItemsCardView } from "./ControlledItemsCardView";
import { ControlledItemsTableView } from "./ControlledItemsTableView";
import { ControlledDynamicItemsViewerProps } from "./ControlledDynamicItemsViewerProps";


export const ControlledDynamicItemsViewer = <T,>(props: ControlledDynamicItemsViewerProps<T>) => {
    
    const [screenSize, setScreenSize] = useState<number>(window.innerWidth);
   
    useEffect( () => {
        const handleResize = () => setScreenSize(window.innerWidth);
        window.addEventListener('resize', handleResize);
    },[])

    return(
        <div className= "dynamic-items-viewer">
            {
                screenSize > props.cutoffScreenSize ? <ControlledItemsTableView    {... props} /> : 
                                                      <ControlledItemsCardView {... props}/>
            }
        </div>
    )
}


